import letter_head from '../../../../../assets/documents/consultation-letter-head.pdf'
import React, { useEffect, useRef, useState } from 'react';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import html2canvas from 'html2canvas';
import './PrescriptionReciept.css'
import useConsultationServices from '../../../../services/api-services/consultationServices';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const PrescriptionReciept = (
    {
        setShowReciept,
        recieptData
    }
) => {
    const [pdfBytes, setPdfBytes] = useState(null);
    const letterheadRef = useRef(null);
    const navigate = useNavigate()

    const {
        putPatientAppointment
    } = useConsultationServices()

    useEffect(() => {
        const loadPDF = async () => {
            const existingPdfBytes = await fetch(letter_head).then((res) => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            const letterheadImage = await html2canvas(letterheadRef.current);
            const letterheadImageBytes = letterheadImage.toDataURL('image/jpeg', 1.0)            

            const page = pdfDoc.getPages()[0];
            const { width, height } = page.getSize();

            const letterheadImageEmbed = await pdfDoc.embedJpg(letterheadImageBytes);
            
            page.drawImage(letterheadImageEmbed, {
                x: 0,
                y: 0,
                blendMode: 'Multiply',
                width: width,
                height: height,
            });

            // Embed the Times Roman font
            const dataTitle = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
            const dataContent = await pdfDoc.embedFont(StandardFonts.Helvetica)

            page.drawText('Patient Name', {
            x: 31,
            y: height - 141,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(":", {
            x: 115,
            y: height - 141,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(recieptData?.patient_details?.name, {
            x: 141,
            y: height - 141,
            size: 11,
            font: dataTitle,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })

            page.drawText('Mobile Number', {
            x: 31,
            y: height - 161,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(":", {
            x: 115,
            y: height - 161,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(recieptData?.patient_details?.mobile, {
            x: 141,
            y: height - 161,
            size: 11,
            font: dataTitle,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })

            page.drawText('Gender', {
            x: 31,
            y: height - 181,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(":", {
            x: 115,
            y: height - 181,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(recieptData?.patient_details?.details?.gender.toUpperCase(), {
            x: 141,
            y: height - 181,
            size: 11,
            font: dataTitle,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })

            page.drawText('Email Address', {
            x: 361,
            y: height - 141,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(":", {
            x: 444,
            y: height - 141,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(recieptData?.patient_details?.email, {
            x: 463,
            y: height - 141,
            size: 11,
            font: dataTitle,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })

            page.drawText('Age', {
            x: 361,
            y: height - 161,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(":", {
            x: 444,
            y: height - 161,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(`${recieptData?.patient_details?.details?.age_year}`, {
            x: 463,
            y: height - 161,
            size: 11,
            font: dataTitle,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })

            page.drawText('Token', {
            x: 361,
            y: height - 181,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(":", {
            x: 444,
            y: height - 181,
            size: 11,
            font: dataContent,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })
            page.drawText(`${recieptData?.token}`, {
            x: 462,
            y: height - 183.5,
            size: 18,
            font: dataTitle,
            color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })

            page.drawLine({
                start: {
                    x:24, 
                    y:height - 200 
                },
                end: { 
                    x:572, 
                    y:height - 200 
                },
                thickness: 2,
                color: rgb(0.39215686274509803, 0.39215686274509803, 0.39215686274509803),
            })

            const modifiedPdfBytes = await pdfDoc.save();
            setPdfBytes(modifiedPdfBytes);
        };

        loadPDF();
    }, []);

    const handleSave = async () => {
        console.log('first')
        try{
            if (pdfBytes) {
                if(recieptData?.id){
                    const data = {
                        status:'confirmed',
                        payment_status:'paid',
                        is_registered_patient:true
                    }
                    const response = await putPatientAppointment(recieptData?.fk_appointment, data)
                    if(response?.success){
                        Swal.fire({
                            title:'Success',
                            icon:'success',
                            timer:1000,
                            showConfirmButton:false
                        })
                        navigate('/appointment-list')
                    }
                }
            }
        }catch(err){console.log(err)}
    };

    const handlePrint = async () => {
        console.log('first')
        try{
            if (pdfBytes) {
                if(recieptData?.id){
                    const data = {
                        status:'confirmed',
                        payment_status:'paid',
                        is_registered_patient:true
                    }
                    const response = await putPatientAppointment(recieptData?.fk_appointment, data)
                    if(response?.success){
                        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        var link = document.createElement('a');
                        link.download = `prescription-slip`;
                        link.href = url;
                        link.click()
                        navigate('/appointment-list')
                    }
                }
            }
        }catch(err){console.log(err)}
    };

    return (
        <div className='row mx-0 p-4 text-center position-relative'>
            <div className="col-12">
                {pdfBytes ? (
                    <div className="pdf-container">
                        <iframe
                            title={`Page 1`}
                            src={
                                URL.createObjectURL(
                                    new Blob(
                                        [pdfBytes], 
                                        { type: 'application/pdf' }
                                    )
                                )
                            }
                            width="80%"
                            height="400px"
                        />
                    </div>
                ) : (
                    <p>Loading PDF...</p>
                )}
                <div className="d-flex justify-content-end mt-4 row mx-0 pe-5 me-2">
                    <div
                        className='form-next-btn btn col-6 me-3'
                        onClick={()=>setShowReciept(false)}
                    >
                        Cancel
                    </div>
                    <div
                        className='form-cancel-btn btn col-6 me-3'
                        onClick={handleSave}
                    >
                        Save
                    </div>
                    <div
                        className='form-next-btn btn col-6 me-0'
                        onClick={handlePrint}
                    >
                        Download & Print
                    </div>
                </div>
            </div>
            <div 
                className="col-12" 
                style={
                    {   
                        position : 'absolute',
                        top : '0',
                        opacity : '0',
                        maxHeight:'0px',
                        overflow:'hidden'
                    }
                }
            >
                
                    <div 
                        ref={letterheadRef} 
                        style={
                            { 
                                width: '21cm', 
                                height: '29.7cm',
                                // background: 'red',
                            }
                        }
                    >
                        <div className='row mx-0 p-4 d-flex align-items-center text-end justify-content-end'>
                            <div className='prescription-physician-details py-3'>
                                <div className='physician-name'>Dr. {recieptData?.physician_details?.name}</div>
                                <div className='physician-detail pt-2 pe-1'>{recieptData?.speciality}</div>
                                <div className='physician-detail py-1 pe-1'>{recieptData?.physician_details?.qualification?.join(', ')}</div>
                            </div>
                        </div>
                        {/* <div className='row mx-0 p-4 d-flex text-start align-items-center'>
                            <div className='prescription-patient-details col-7 row mx-0 py-1'>

                                <div className='title py-1 col-3 col-4'>Patient Name</div>
                                <div className='colon py-1 col-1 px-0'>:</div>
                                <div className='detail py-1 col-7 col-8 ps-0'>{recieptData?.patient_details?.name}</div>

                                <div className='title py-1 col-3 col-4'>Mobile Number</div>
                                <div className='colon py-1 col-1 px-0'>:</div>
                                <div className='detail py-1 col-7 col-8 ps-0'>{recieptData?.patient_details?.mobile}</div>

                                <div className='title py-1 col-3 col-4'>Gender</div>
                                <div className='colon py-1 col-1 px-0'>:</div>
                                <div className='detail py-1 col-7 col-8 ps-0'>{recieptData?.patient_details?.details?.gender.toUpperCase()}</div>

                            </div>
                            <div className='prescription-patient-details col-5 row mx-0 py-1'>

                                <div className='title py-1 col-5'>Email Address</div>
                                <div className='colon py-1 col-1 px-0'>:</div>
                                <div className='detail py-1 col-6 ps-0'>{recieptData?.patient_details?.email}</div>

                                <div className='title py-1 col-5'>Age</div>
                                <div className='colon py-1 col-1 px-0'>:</div>
                                <div className='detail py-1 col-6 ps-0'>{recieptData?.patient_details?.details?.age_year}</div>

                                <div className='title py-1 col-5'>Token</div>
                                <div className='colon py-1 col-1 px-0'>:</div>
                                <div className='token py-1 col-6 ps-0'>{recieptData?.token}</div>

                            </div>
                            <div className='col-12'>
                                <hr />
                            </div>
                        </div> */}
                    </div>
                
            </div>
        </div>

    );
};

export default PrescriptionReciept;