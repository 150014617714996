import dashboard_icon from '../../../assets/svgs/dashboard_side.svg'
import patient_icon from '../../../assets/svgs/patient-side.svg'
import physician_icon from '../../../assets/svgs/doctor-side.svg'
import card_icon from '../../../assets/svgs/id-card-side.svg'
import appointment_icon from '../../../assets/svgs/app-list-cal.svg'
import leave_icon from '../../../assets/svgs/leave-side.svg'
import room_icon from '../../../assets/svgs/room-side.svg'
import React, { useEffect, useState } from 'react'
import './Sidebar.css'
import SliderLink from './components/SliderLink'
import { useLocation } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import logoandtext from '../../../assets/pictures/icons/svg/Group 3.svg'
const Sidebar = () => {
  const [linklist, setLinklist] = useState([])

  const { auth } = useAuth()

  const location = useLocation()

  const adminLinks = [
    { title: 'Dashboard', icon: dashboard_icon, path: "/" },
    { title: "Patients", icon: patient_icon, path: "/patient-list" },
    { title: "Physicians", icon: physician_icon, path: "/physician-list" },
    { title: "Appointments", icon: appointment_icon, path: "/appointment-list" },
    // { title: "Consultation", icon: room_icon, path: "/physician-appointment-list" },
    { title: "Generate Card", icon: card_icon, path: "/patient-card-generate" },
    { title: "Leave Requests", icon: leave_icon, path: "/leave-requests" },
    
  ]

  const hospitalLinks = [
    { title: 'Dashboard', icon: dashboard_icon, path: "/" },
    { title: "Patients", icon: patient_icon, path: "/patient-list" },
    { title: "Physicians", icon: physician_icon, path: "/physician-list" },
    { title: "Appointments", icon: appointment_icon, path: "/appointment-list" },
    { title: "Generate Card", icon: card_icon, path: "/patient-card-generate" },
  ]
  const doctorLinks = [
    // { title: 'Dashboard', icon: dashboard_icon, path: "/" },
    // { title: "Patients", icon: patient_icon, path: "/patient-list" },
    // { title: "Physicians", icon: physician_icon, path: "/physician-list" },
    { title: "Appointments", icon: appointment_icon, path: "/physician-appointment-list" },
    // { title: "Generate Card", icon:card_icon, path: "/patient-card-generate" },
  ]
  useEffect(() => {
    if (auth?.role) {
      switch (auth.role) {
        case "Hospital":
          return setLinklist([...hospitalLinks])
        case "Physician":
          return setLinklist([...doctorLinks])
        case "Admin":
          return setLinklist([...adminLinks])
      }
    }
  },[auth.role])
  const links = linklist.map(
    (link, index) =>
      <SliderLink
        {...link}
        key={index}
        classList={`${location.pathname === link.path ? 'active' : ''}`}
      />
  )

  return (
    <div className='col-md-2 d-none d-md-block sidebar-container mx-0 my-5 px-5'>
      <div className='logo-container mb-4 d-flex justify-content-center mt-2'>
        <img src={logoandtext} alt="" />
      </div>
      <div className="link-container pt-5">
        {links}
      </div>
    </div>
  )
}

export default Sidebar
