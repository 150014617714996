import React, { useEffect, useState } from 'react'
import { ButtonToolbar, Form, InputGroup, OverlayTrigger } from 'react-bootstrap'
import './TextInput.css'

const InputBtnGroup = (props) => {
    const {
        label,
        icon,
        required,
        containerClass,
        placeholder,
        type,
        defvalue,
        onClick,
        triggerType,
        popupContent,
        popupPlacement,
        togglePopup,
        setTogglePopup,
        disabled
    } = props
    const [tempValue, setTempValue] = useState('')
    const [showPopup, setShowPopup] = useState(false)
    useEffect(()=>{
        setTempValue(defvalue)
    },[defvalue])
    useEffect(()=>{
        setShowPopup(togglePopup)
    },[togglePopup])
    const handleClick = () => {
        try {
            onClick(tempValue)
        }
        catch { }
    }
    const handlePopupTrigger = () => {
        setShowPopup(!showPopup)
        try {
            setTogglePopup(!showPopup)
        }
        catch { }
    }

    return (
        <Form.Group
            className={containerClass}
        >

            <Form.Label
                className='label-text-content'
            >
                {label || ''}
                {required && ' *'}
            </Form.Label>
            <InputGroup className='d-flex align-items-center'>
                <Form.Control
                    type={type?type:'text'}
                    className='icon-input-box mb-0'
                    value={tempValue}
                    onChange={(e) => setTempValue(e.target.value)}
                    required={required ? required : false}
                    disabled={disabled ? disabled : false}
                    placeholder={placeholder?placeholder:''}
                />
                {props.popupContent ?
                    <ButtonToolbar>
                        <OverlayTrigger
                            show={showPopup}
                            trigger={triggerType ? triggerType : ['click']}
                            placement={popupPlacement ? popupPlacement : "left"}
                            overlay={popupContent}
                        >
                            <InputGroup.Text className='icon-input-btn btn' onClick={handlePopupTrigger}>
                                {icon}
                            </InputGroup.Text>
                        </OverlayTrigger>
                    </ButtonToolbar> :
                    <InputGroup.Text className='icon-input-btn btn' onClick={handleClick}>
                        {icon}
                    </InputGroup.Text>
                }
            </InputGroup>
        </Form.Group>
    )
}

export default InputBtnGroup
