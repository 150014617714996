import React, { useState } from 'react'

const SubInvestigationReport = ({data}) => {
    const [isActive, setIsActive] = useState('');
    
    const handleClick = (sec) => {
      if (isActive == sec) {
        setIsActive('')
      }
      else {
        setIsActive(sec)
      }
    }
  

  return (
    <div>
        <label className='mb-2 fw-bold h6'>Investigation Report</label>
        <div className='row invest-row'>
          {data&&data?.map((item,index)=>{
            return(
              <>
              <div className='invest-test col-8 bg-white'>{item?.test_name}</div>
              <div className='invest col-2 bg-dark text-white'>Report</div>
              <div className='invest col-2 bg-secondary text-white mouse-pointer' onClick={()=>handleClick(index+1)} >Remark</div>
              <div className={`invest-remark col-12 bg-white p-3 ${isActive != index+1 ? 'd-none' : 'd-block' }`}>{item?.remark}</div>
              </>
            )
          })}
            
        </div>
    </div>
  )
}

export default SubInvestigationReport