import patient_details from "../../../../assets/pictures/icons/patient-details-icon.png";
import medical_records from "../../../../assets/pictures/icons/medical-records-icon.png";
import patient_assessment from "../../../../assets/pictures/icons/patient-assessment-icon.png";
import patient_report from "../../../../assets/pictures/icons/patient-consultation-report.png";
import patient_visiting_history from "../../../../assets/pictures/icons/patient-visiting-history.png";
import React, { useEffect, useState } from "react";
import { Nav, Tab, TabPane } from "react-bootstrap";
import PatientDetails from "./components/tabpanes/PatientDetails";
import "./PhysicianConsultation.css";
import useConsultationServices from "../../../services/api-services/consultationServices";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import MedicalRecords from "../../reception/patient/registration/components/TabPanes/MedicalRecords";
import Swal from "sweetalert2";
import usePatientServices from "../../../services/api-services/patientServices";
import useGetListDatas from "../../../services/other-services/getListDatas";
import moment from "moment";
import AssessmentInvestigation from "./components/tabpanes/AssessmentInvestigation";
import InputTextBox from '../../../shared/form-components/form-text/InputTextBox'
import Del from "../../../../assets/pictures/ant-design-clear-outlined.png"
import Rec from "../../../../assets/pictures/rec.png"
import ArrowDown from "../../../../assets/pictures/arrow-1.png"
import ArrowUp from "../../../../assets/pictures/arrow-2.png"
import VisitingHistory from "./components/tabpanes/VisitingHistory";
import useOnKey from "../../../shared/form-components/onkeydown/useOnKey";

const PhysicianConsultation = () => {
  const [key, setKey] = useState("tab1");
  const [toggle, setToggle] = useState(false);

  const [patientDetails, setPatientDetails] = useState();
  const [basicId, setBasicId] = useState("");

  // Medical records
  const [prevMedicalRecords, setPrevMedicalRecords] = useState([]);
  const [currentMedicalRecords, setCurrentMedicalRecords] = useState([]);
  const [treatmentHistory, setTreatmentHistory] = useState([]);
  const [allergyList, setAllergyList] = useState([]);
  const [patientDocuments, setPatientDocuments] = useState([]);
  const [documentCategoryList, setDocumentCategoryList] = useState("");
  const [documentCategory, setDocumentCategory] = useState("");
  const [documentIssueDate, setDocumentIssueDate] = useState("");
  const [documentValidity, setDocumentValidity] = useState("");
  const [documentList, setDocumentList] = useState(undefined);

  //Assessment
  const [prIssue, setPrIssue] = useState('')
  const [diagnosis,setDiagnosis] = useState('')

  //Prescriptions
  const [medicineName,setMedicineName] = useState('');
  const [prescId,setPrescId] = useState('');
  const [dose,setDose] = useState('');
  const [times,setTimes] = useState('');

  const [prescriptionList,setPrescriptionList] = useState([]);

  const [ref,setRef] = useState('');

  const { appointment_id } = useParams();
  const navigate = useNavigate();

  const { getPatientDetail, getMedicalPrescription} = useConsultationServices();

  const { postMedicalPrescription } = useConsultationServices();

  const { delMedicalPrescription }=useConsultationServices();

  const {postAppointmentAssessmentdetails}=useConsultationServices();

  const { putPatientAppointment,putMedicalPrescription } = useConsultationServices();

  const {
    getPatientDocumentCategories,
    getPatientMedicalIssues,
    getPatientTreatmentHistory,
    getPatientAllergicIssues,
    getPatientMedicalDocuments,
  } = usePatientServices();

  const {
    postPatientMedicalIssues,
    postPatientTreatmentHistory,
    postPatientAllergicIssues,
    postPatientDocumentCategories,
    postPatientMedicalDocuments,
  } = usePatientServices();
  const {
    deletePatientMedicalIssue,
    deletePatientTreatmentHistory,
    deletePatientAllergicIssues,
  } = usePatientServices();

  const { getSemanticOptionsList, getMultiInputList } = useGetListDatas();


  const patientInfo = async () => {
    const response = await getPatientDetail(appointment_id);
    if (response.success) {
      console.log(response?.data?.patient_details?.details?.id)
      setPatientDetails(response?.data?.patient_details?.details);
      setBasicId(response?.data?.patient_details?.details?.id);
    }
    // console.log(response.data);
  };

  const patientDocumentCategories = async () => {
    try {
      const response = await getPatientDocumentCategories();
      if (response.success) {
        const data = response.data;
        setDocumentCategoryList(getSemanticOptionsList(data, "id", "category"));
      }
    } catch {}
  };

  const getPrevMedIssues = async () => {
    const params = {
      present: 0,
    };
    try {
      const response = await getPatientMedicalIssues(basicId, params);
      if (response?.success) {
        const issues = response?.data;
        setPrevMedicalRecords(getMultiInputList(issues, "id", "issue"));
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const getCurrentMedIssues = async () => {
    const params = {
      present: 1,
    };
    try {
      const response = await getPatientMedicalIssues(basicId, params);
      if (response?.success) {
        const issues = response?.data;
        setCurrentMedicalRecords(getMultiInputList(issues, "id", "issue"));
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const addCurrentMedIssues = async (value) => {
    try {
      const data = JSON.stringify({
        issue: value,
        is_present_issue: true,
      });
      const response = await postPatientMedicalIssues(basicId, data);
      if (response?.success) {
        getCurrentMedIssues();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const addPrevMedIssues = async (value) => {
    try {
      const data = JSON.stringify({
        issue: value,
        is_present_issue: false,
      });
      const response = await postPatientMedicalIssues(basicId, data);
      if (response?.success) {
        getPrevMedIssues();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const deleteMedIssue = async (issueId) => {
    try {
      const response = await deletePatientMedicalIssue(basicId, issueId);
      if (response?.success) {
        getCurrentMedIssues();
        getPrevMedIssues();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const getPatientTreatmentRecords = async () => {
    try {
      const response = await getPatientTreatmentHistory(basicId);
      if (response?.success) {
        const treatments = response?.data;
        setTreatmentHistory(getMultiInputList(treatments, "id", "treatment"));
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const addTreatment = async (value) => {
    try {
      const data = JSON.stringify({
        treatment: value,
      });
      const response = await postPatientTreatmentHistory(basicId, data);
      if (response?.success) {
        getPatientTreatmentRecords();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const removeTreatment = async (treatmentId) => {
    try {
      const response = await deletePatientTreatmentHistory(
        basicId,
        treatmentId
      );
      if (response?.success) {
        getPatientTreatmentRecords();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const getPatientAllergicRecords = async () => {
    try {
      const response = await getPatientAllergicIssues(basicId);
      if (response?.success) {
        const issues = response?.data;
        setAllergyList(getMultiInputList(issues, "id", "issue"));
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const addAllergy = async (value) => {
    try {
      const data = JSON.stringify({
        issue: value,
      });
      const response = await postPatientAllergicIssues(basicId, data);
      if (response?.success) {
        getPatientAllergicRecords();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const deleteAllergy = async (issueId) => {
    try {
      const response = await deletePatientAllergicIssues(basicId, issueId);
      if (response?.success) {
        getPatientAllergicRecords();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const getPatientDocuments = async () => {
    try {
      const response = await getPatientMedicalDocuments(basicId);
      if (response?.success) {
        setPatientDocuments(response?.data);
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const postPatientDocumentType = async (value) => {
    try {
      const data = JSON.stringify({
        category: value.value,
      });
      const response = await postPatientDocumentCategories(data);
      if (response?.success) {
        setDocumentCategory(response?.data?.id);
        patientDocumentCategories();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const handleDocumentUpload = async () => {
    //set Medical Document Data
    const medicalDocuments = new FormData();
    medicalDocuments.append("category", documentCategory);
    medicalDocuments.append(
      "issued_date",
      documentIssueDate &&
        moment(new Date(documentIssueDate)).format("YYYY-MM-DD")
    );
    medicalDocuments.append(
      "validity_upto",
      documentValidity &&
        moment(new Date(documentValidity)).format("YYYY-MM-DD")
    );
    documentList?.map((file, index) => {
      medicalDocuments.append("document" + (index + 1), file);
    });
    // console.log(medicalDocuments)
    try {
      const response = await postPatientMedicalDocuments(
        basicId,
        medicalDocuments
      );
      if (response?.success) {
        setDocumentCategory("");
        setDocumentIssueDate("");
        setDocumentValidity("");
        setDocumentList(undefined);
        getPatientDocuments();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };
  
  const {handleKeyDown,formRef} = useOnKey(ref,setRef);

  const handleKeySubmit = (e)=>{
    if (e.type=="keydown"){
      console.log(e)
      if(e.key == "Enter"){
        if(prescId!=''){
          putPrescription()
        }
        else{
          postPrescription()
        }
      }
    }
  }
  const getPrescription =async()=>{
    try{
        const response = await getMedicalPrescription(appointment_id)
        if(response?.success){
          setPrescriptionList(response?.data)
        }
    }catch(err){
        console.log(err?.response)
    }
}

const postPrescription = async()=>{
    try{
        const data={
          medicine_name:medicineName,
          dose:dose,
          days:times,
        }
        const response = await postMedicalPrescription(appointment_id ,data)
        if(response?.success){
          getPrescription()
          setMedicineName('')
          setDose('')
          setTimes('')
            Swal.fire({
                text:response?.message,
                icon:'success',
                timer: 1000,
                showConfirmButton:false
            })
        }
    }catch(err){
        console.log(err?.response)
    }
}

const handlePrescriptionEdit =(data)=>{
  setPrescId(data?.id)
  setMedicineName(data?.medicine_name)
  setDose(data?.dose)
  setTimes(data?.days)
}

const putPrescription = async()=>{
  try{
      const data={
        medicine_name:medicineName,
        dose:dose,
        days:times,
      }
      const response = await putMedicalPrescription(prescId ,data)
      if(response?.success){
        getPrescription()
        setPrescId('')
        setMedicineName('')
        setDose('')
        setTimes('')
          Swal.fire({
              text:response?.message,
              icon:'success',
              timer: 1000,
              showConfirmButton:false
          })
      }
  }catch(err){
      console.log(err?.response)
  }
}

const delPrescription =async(id)=>{
  try{
      const response = await delMedicalPrescription(id)
      if(response?.success){
        getPrescription()
        Swal.fire({
          text:response?.message,
          icon:'success',
          timer: 1000,
          showConfirmButton:false
      })
      }
  }catch(err){
      console.log(err?.response)
  }
}

const deletePress = async (item) => {
  Swal.fire({
    title: 'Delete',
    text: `Are you sure to delete ${item.medicine_name} medicine ?`,
    icon: "question",
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Yes",
    denyButtonText: "Cancel",
  }).then(async (result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'Delete',
        text: `Confirm deletion`,
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        denyButtonText: "Cancel",
        showLoaderOnConfirm: true,
        preConfirm: async () => {

          await delPrescription(item.id)
        },
        preDeny: () => {
          Swal.fire('Cancelled', '', 'info')
        }
      })
    } 
    })
  }
  const postAssessmentdetails = async()=>{
    try{
        const data={
          present_issue:prIssue,
          diagnosis:diagnosis
        }
        const response = await postAppointmentAssessmentdetails(appointment_id ,data)
        if(response?.success){
            Swal.fire({
                text:response?.message,
                icon:'success',
                timer: 1000,
                showConfirmButton:false
            })
        }
    }catch(err){
        console.log(err?.response)
    }
  }
  const handleFinish= async()=>{
    const data = {
      status:'completed',
      is_registered_patient: true,
    }
    const response = await putPatientAppointment(appointment_id, data)
    if(response?.success){
        Swal.fire({
            title:'Success',
            icon:'success',
            timer:1000,
            showConfirmButton:false
        })
        navigate('/physician-appointment-list')
    }
  }


  useEffect(() => {
    if(appointment_id){
      patientInfo();
      getPrescription();
    } 
    patientDocumentCategories();
  }, []);

  useEffect(() => {
    if (basicId) {
      getPrevMedIssues();
      getCurrentMedIssues();
      getPatientTreatmentRecords();
      getPatientAllergicRecords();
      getPatientDocuments();
    }
  }, [basicId]);

  return (
    <div className="">
      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
        <div className="col-12">
          <Nav className="tabpane-container">
            <Nav.Item className="content">
              <Nav.Link className="tabpane-item" eventKey="tab1">
                <img src={patient_details} className="details-icon" alt="" />
                &nbsp; Patient Details
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="content">
              <Nav.Link className="tabpane-item" eventKey="tab2">
                <img src={medical_records} className="medical-icon" alt="" />
                &nbsp; Medical Records
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="content">
              <Nav.Link className="tabpane-item" eventKey="tab3">
                <img
                  src={patient_assessment}
                  className="physician-documents-icon"
                  alt=""
                />
                &nbsp; Assessment & Investigation
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="content">
              <Nav.Link className="tabpane-item" eventKey="tab4">
                <img
                  src={patient_report}
                  className="physician-documents-icon"
                  alt=""
                />
                &nbsp; Report
              </Nav.Link>
            </Nav.Item>
            <div className='d-flex justify-content-end col pe-4'>
              <div
                className='buttonview me-1'
                >
                Cancel
              </div>
              <div
                className='buttonview me-1'
                onClick={postAssessmentdetails}
                >
                Save
              </div>
              <div
                className='buttonview me-1'
                onClick={handleFinish}
                >
                Finish
              </div>
              <div>
                
              </div>
            </div>
          </Nav>
           
        </div>
        <div className="pagebody-container">
          <Tab.Content className="tabcontent-container">
            <TabPane eventKey="tab1" className="">
              <PatientDetails
                {...{
                  patientDetails,
                }}
              />
            </TabPane>
            <TabPane eventKey="tab2" className="pb-5 mb-5">
              <MedicalRecords
                value={{
                  ...{
                    prevMedicalRecords,
                    currentMedicalRecords,
                    treatmentHistory,
                    allergyList,
                    documentCategoryList,
                    documentCategory,
                    documentIssueDate,
                    documentValidity,
                    documentList,
                    patientDocuments,
                  },
                }}
                setValue={{
                  ...{
                    setPrevMedicalRecords,
                    setCurrentMedicalRecords,
                    setTreatmentHistory,
                    setAllergyList,
                    setDocumentCategory,
                    setDocumentIssueDate,
                    setDocumentValidity,
                    setDocumentList,
                  },
                }}
                functions={{
                  ...{
                    addCurrentMedIssues,
                    addPrevMedIssues,
                    addTreatment,
                    addAllergy,
                    deleteMedIssue,
                    removeTreatment,
                    deleteAllergy,
                    handleDocumentUpload,
                    postPatientDocumentType,
                  },
                }}
                consult={true}
                setKey={setKey}
              />
            </TabPane>
            <TabPane eventKey="tab3" className="mb-5 pb-3">
            <AssessmentInvestigation
              value={{
                ...{
                  prIssue,
                  diagnosis
                }
              }}
              setValue={{
                ...{
                  setPrIssue,
                  setDiagnosis,
                }
              }}
            
            />
            </TabPane>
            <TabPane eventKey="tab4" className="">

            <VisitingHistory
              {...{basicId}}
            />

            </TabPane>
            
          </Tab.Content>
          <div className={`fixed ${toggle? 'popup':''}`} >

            <div className="bottom_content bg-dark"> 
                <button
                  className="bottom_content_btn btn mx-4 bg-dark"
                  onClick={()=>setToggle(!toggle)}>
                  {toggle ? ( <img src={ArrowDown} /> ) : ( <img src={ArrowUp} /> )}
                </button>		
            </div>
            
            <div className='content px-5 m-5'>
              <table width={'100%'} style={{"text-align":"center"}}>
                <tr>
                  <th>Sl.No</th>
                  <th>Medicine Name</th>
                  <th>Dose</th>
                  <th>Days</th>
                </tr>
                <tr ref={formRef}>
                  <td>00</td>
                  <td><input type="text" className="input-text-control px-3 w-100 custom_input" size={25} value={medicineName||''} onChange={(e)=>setMedicineName(e.target.value)} onKeyDown={handleKeyDown}/></td>
                  <td><input type='text' className="input-text-control px-3 w-100 custom_input" size={25} value={dose||''} onChange={(e)=>setDose(e.target.value)}  onKeyDown={handleKeyDown}/></td>
                  <td><input type="text" className="input-text-control px-3 w-100 custom_input" size={5} value={times||''} onChange={(e)=>setTimes(e.target.value)} onKeyDown={handleKeySubmit}/></td>
                  <td><img src={Del} alt=""/></td>
                </tr>
                {prescriptionList.length>0 && prescriptionList.map((item,i)=>
                <tr onClick={()=>handlePrescriptionEdit(item)} className="mouse-pointer">
                  <td>{i+1}</td>
                  <td>{item?.medicine_name}</td>
                  <td>{item?.dose}</td>
                  <td>{item?.days}</td>
                  <td><img src={Del} alt="" onClick={()=>deletePress(item)}  /></td>
                </tr>
                )}
              </table>  
                
            </div>        
          </div>

        </div>
      </Tab.Container>
    </div>
  );
};

export default PhysicianConsultation;
