import "./superadmin.css";
import InputTextBox from "../../shared/form-components/form-text/InputTextBox";
import { useEffect, useState } from "react";
import useSuperAdminServices from "../../services/api-services/superAdminServices";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const SuperAdmin = () => {
  const navigate = useNavigate();
  const { postAdminRegistration, getAdminStatus } = useSuperAdminServices();
  const [admin, setAdmin] = useState({
    first_name: "",
    last_name: "",
    username: "",
    password: "",
  });
  const [check, setCheck] = useState({});

  const checkAdmin = async () => {
    try {
      const response = await getAdminStatus();
      setCheck(response.data);
      console.log("check admin", response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkAdmin();
  }, []);

  const onInputChange = (e, name) => {
    setAdmin({ ...admin, [name]: e.target.value });
  };

  const onRegisterClick = async (e) => {
    e.preventDefault();
    try {
      const response = await postAdminRegistration(admin);
      console.log("super response", response);
      Swal.fire("", "Admin Registered Successfully", "success");
      setAdmin({
        first_name: "",
        last_name: "",
        username: "",
        password: "",
      });
    } catch (error) {
      console.log(error);
      Swal.fire("", "Registration failed", "error");
    }
  };

  console.log("admin", admin);

  return (
    <>
      <div className="admin-page-container">
        {check.exist ? (
          <>
            <div className="card text-center col-4">
              <div className="card-body">
                <p className="card-text fs-3">Admin already have an Account</p>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  please go to login page
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="admin-container">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                    <form
                      onSubmit={onRegisterClick}
                      className="border border-dark p-2 rounded"
                    >
                      <h3 className="text-center fw-bolder">Admin Register</h3>
                      <div className="w-100">
                        <div className="d-flex justify-content-center p-3">
                          <div className="col-4 text-start">
                            <label className="fw-bold">First Name *</label>
                          </div>
                          <div className="col-8 ps-2">
                            <input
                              type="text"
                              value={admin.first_name}
                              name="first_name"
                              onChange={(e) => onInputChange(e, "first_name")}
                              className="border rounded py-1 w-100 border-secondary p-2"
                              required
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center p-3">
                          <div className="col-4 text-start">
                            <label className="fw-bold">Last Name *</label>
                          </div>
                          <div className="col-8 ps-2">
                            <input
                              type="text"
                              value={admin.last_name}
                              name="last_name"
                              onChange={(e) => onInputChange(e, "last_name")}
                              className="border rounded py-1 w-100 border-secondary p-2"
                              required
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center p-3">
                          <div className="col-4 text-start">
                            <label className="fw-bold">Username *</label>
                          </div>
                          <div className="col-8 ps-2">
                            <input
                              type="text"
                              value={admin.username}
                              name="username"
                              onChange={(e) => onInputChange(e, "username")}
                              className="border rounded py-1 w-100 border-secondary p-2"
                              required
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center p-3">
                          <div className="col-4 text-start">
                            <label className="fw-bold">Password *</label>
                          </div>
                          <div className="col-8 ps-2">
                            <input
                              type="password"
                              value={admin.password}
                              name="password"
                              onChange={(e) => onInputChange(e, "password")}
                              className="border rounded py-1 w-100 border-secondary p-2"
                              required
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center p-3">
                          <button
                            type="submit"
                            className="p-2 border rounded bg-primary text-light"
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SuperAdmin;
