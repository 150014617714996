import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAxios from '../../hooks/useAxios'

const useHospitalServices = () => {
    const axiosPrivate = useAxiosPrivate()
    const axios = useAxios()

    const postHospitalRegistration = async(data)=>{
        const response =await axiosPrivate.post(`account/hospital_register/`,data,{headers:{'Content-Type': 'multipart/form-data'}})
        return response.data
    }

    return {
        //POST REQUESTS
        postHospitalRegistration,
    }
}
export default useHospitalServices