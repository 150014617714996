import React, { useState } from 'react'
import InputTextBox from '../../../../../../shared/form-components/form-text/InputTextBox'
import InputUploadBox from '../../../../../../shared/form-components/form-upload/InputUploadBox'
import { Form } from 'react-bootstrap'
import PhysicianDocList from '../PhysicianDocList'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import DropDownBox from '../../../../../../shared/form-components/dropdown/CustomDropDown'

const PhysicianDocuments = ({ value, setValue, functions }) => {
    const [showDropdown, setShowDropdown] = useState('')
    const navigate = useNavigate()
    return (
        <div className='row mx-0'>
            <div className="col-12 page-title-label">Physician Details</div>
            <div className='left-split-container col-6 row'>
                <InputTextBox
                    label={'Document Name'}
                    containerClass={'col-6 mb-3'}
                    onChange={setValue?.setDocumentName}
                    defvalue={value?.documentName}
                />
                {value?.documentTypeList&&<DropDownBox
                    label={'Doc Type'}
                    containerClass={'col-6 mb-3'}
                    addNew={true}
                    fluid={true}
                    search={true}
                    selection={true}
                    setNew={functions?.addPhysicianDocumentType}
                    options={value?.documentTypeList}
                    setDataValue={setValue?.setDocumentType}
                    selectedValue={value?.documentType}
                />}
                <div className='col-12 row mx-0 px-3'>
                    <Form.Label className='label-text-content col-12 px-0'>
                        Upload Document
                    </Form.Label>
                    <div className='physician-document-upload col-12 py-2'>
                        <InputUploadBox
                            containerClass={''}
                            height={'14.375rem'}
                            bgColor={'#F1F1F1'}
                            handleFile={setValue?.setDocumentFile}
                            fileValue={value?.documentFile}
                            id={'physician-document-upload'}
                        />
                        <div className="row my-3">
                            <span className="col-6" />
                            <div className="col-3">
                                <div className='document-clear-btn btn my-1' onClick={()=>setValue?.setDocumentFile('')}>
                                    Clear
                                </div>
                            </div>
                            <div className="col-3">
                                <div 
                                    className='upload-submit-btn btn my-1'
                                    onClick={functions?.handlePhysicianDocumentUpload}
                                >
                                    Submit
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-6 row mx-0 ps-4 pe-0'>
                <div className='col-12'>
                    <PhysicianDocList
                        data={value?.documentList}
                        handleDelete={functions?.deleteDocument}
                        setDocName={setValue?.setDocumentName}
                        setDocType={setValue?.setDocumentType}
                        setDocFile={setValue?.setDocumentFile}
                    />
                </div>
            </div>
            <div className="col-12 row">
                <span className='col-6 mt-4' />
                <div className="col-6 mt-4 row d-flex justify-content-end pe-0">
                    <div
                        className='form-previous-btn btn col-3 me-3'
                        onClick={(e) => {
                            setValue?.setKey('tab1')
                            functions?.getPhysicianQualificationList()
                        }}
                    >
                        Previous
                    </div>
                    <div
                        className='form-cancel-btn btn col-3 me-3'
                        onClick={()=>navigate('/physician-list')}
                    >
                        Cancel
                    </div>
                    <div
                        className='form-next-btn btn col-3 me-0'
                        onClick={functions.handleTabChange}
                    >
                        Save & Next
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PhysicianDocuments
