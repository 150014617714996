import React, { useEffect, useState } from 'react'
import DashboardChart from './components/DashboardChart'
import doclogo from '../../../assets/pictures/icons/svg/2.svg'
import applogo from '../../../assets/pictures/icons/svg/1.svg'
import patlogo from '../../../assets/pictures/icons/svg/3.svg'
import AppointmentTable from './components/AppointmentTable'
import PhysicianTable from './components/PhysicianTable'
import './Dashboard.css'
import useAccountServices from '../../services/api-services/accountServices'
const Dashboard = () => {
    const initialDatas = [
        {name:"Total Appointments", value:"100",image : applogo,type:"appointments"},
        {name:"Total Physicans", value:"100",image : doclogo,type:"physicians"},
        {name:"Total Patients", value:"100",image : patlogo,type:"patients"}]
    const [initialData,setInitial] = useState(initialDatas)
    const [weekNames,setWeekNames] =useState([])
    const [weekData,setWeekData] = useState([])
    const [monthNames,setMonthNames]= useState([])
    const [monthData,setMonthData] = useState([])
    const [statisticsData,setStatisticsData] = useState({})
    const [latestAppointmentData,setLatestAppointmentData] = useState([])
    const{getDashboard} = useAccountServices()

    const AllDashboardDetails= async()=>{
        const response = await getDashboard()
        console.log(response.data)
        if (response.success){
            setWeekNames(Object.keys(response?.data?.analytics?.weekly))
            setWeekData(Object.values(response?.data?.analytics?.weekly))
            setMonthNames(Object.keys(response?.data?.analytics?.monthly))
            setMonthData(Object.values(response?.data?.analytics?.monthly))
            setStatisticsData(response?.data?.statistics)
            setLatestAppointmentData(response?.data?.latest_appointments)
        }
    }
    useEffect(()=>{
        AllDashboardDetails()
    },[])
    useEffect (()=>{
        const updatedData = initialData.map((data) => {
            const newValue = statisticsData[data.type];
            return {
              ...data,
              value: newValue !== undefined ? newValue.toString() : data.value,
            };
          });      
          setInitial(updatedData);
    },[statisticsData])

  return (
    <div className='me-5 ms-3 vh-100'>
        <div className='mainhead row'>
            <div className='subhead col-4 px-5 py-3'>
                {initialData.map((d,i)=>{
                    return(
                        <div className='row border rounded mb-2 p-1' key={i}>
                        <div className='col-3 p-3 ps-5 d-flex justify-content-center rounded'>
                            <img src={d.image} alt="" />
                        </div>
                        <div className='col-9 ps-5'>
                            <div className='mt-3 pt-2'><h2>{d.value}+</h2></div>
                            <div className='py-1'><h4>{d.name}</h4></div>
                        </div>
                    </div>
                    )
                })}
            </div>
            <div className='subgraph col-8 p-5'>
              <DashboardChart {...{weekNames,weekData,monthData,monthNames}}/>
            </div>
        </div>
        <div className='mainbase row'>
        <div className='baselist1 col-8 row p-4'>
            <div className='w-100 ms-3'> 
                <h3>Appointments</h3>
                <AppointmentTable {...{latestAppointmentData}}/>
            </div>
        </div>
        <div className='baselist2 col-4 row p-4'>
            <div className='w-100 ms-3'>
                <h3>Available Physicians</h3>
                <PhysicianTable/>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Dashboard