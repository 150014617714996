import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import './Textarea.css'

const TextAreaBox = (props) => {
    const {
        label, 
        required, 
        containerClass, 
        inputClass,
        placeholder, 
        rows,
        disabled, 
        onChange,
        maxLength,
        hint,
        hintId,
        defvalue
    } = props
    const [tempValue, setTempValue] = useState('')
    useEffect(()=>{
        setTempValue(defvalue)
    },[defvalue])
    const handleChange=(e)=>{
        try{
            onChange(e.target.value)
        }
        catch{}
    }
  return (
    <Form.Group
        className={containerClass}
    >
        <Form.Label
            className='label-text-content'
        >
            {label&&label}
            {required && <span className='text-danger'> *</span>}
        </Form.Label>
        <Form.Control
            as='textarea'
            rows={rows}
            value={tempValue}
            maxLength={maxLength?maxLength:null}
            className={`input-textarea-control ${inputClass}`}
            required={required?required:false}
            disabled={disabled?disabled:false}
            placeholder={placeholder||''}
            onChange={handleChange}
            aria-describedby={hintId?hintId:null}
        />
        {hint&&<Form.Text id={hintId} muted>
            {hint}
        </Form.Text>}
    </Form.Group>
  )
}

export default TextAreaBox
