import { useState, useEffect } from 'react';
import CheckBoxBtn from '../../../../../../../shared/form-components/checkbox/CheckBoxBtn'

const GenderForm = ({ value, setValue }) => {
  const [error, setError] = useState(null);

  // Validation function
  const validateGenderSelection = () => {
    if ((value?.dOB || value?.ageYear) && !value?.gender) {
      console.log('Validating gender selection:', value);
      setError('Please select a gender.');
    } else {
      setError(null); // Clear error if gender is selected
    }
  };

  // Trigger validation when dob, ageyear, or gender changes
  useEffect(() => {
    validateGenderSelection();
  }, [value?.dOB, value?.ageYear, value?.gender]);

  return (
    <>
      <CheckBoxBtn
        label={'Gender'}
        required={true}
        content={'Male'}
        value={'male'}
        setSelected={setValue?.setGender}
        selected={value?.gender}
        containerClass={'col-2 mb-3 age-form-container1'}
      />
      <CheckBoxBtn
        content={'Female'}
        value={'female'}
        setSelected={setValue?.setGender}
        selected={value?.gender}
        containerClass={'col-2 mb-3 mt-1 age-form-container2'}
      />
      <CheckBoxBtn
        content={'Other'}
        value={'other'}
        setSelected={setValue?.setGender}
        selected={value?.gender}
        containerClass={'col-2 mb-3 mt-1 age-form-container3'}
      />

      {error && <div className="text-end fw-200" style={{ color: 'rgb(204, 46, 70)', fontSize:'12px'}}>{error}</div>}
    </>
  );
};

export default GenderForm;
