import React, { useEffect, useState } from 'react'
import { BiSolidDownload } from 'react-icons/bi'
import { IoEyeSharp } from 'react-icons/io5'
// import { MdEdit } from 'react-icons/md'
import { RiDeleteBin6Fill } from 'react-icons/ri'
import { MEDIA_URL } from '../../../../../axios'
// import { TbPencil } from "react-icons/tb";
import Swal from 'sweetalert2'

const PhysicianDocList = ({ data, setDocName, setDocType, setDocFile, handleDelete }) => {
    const [docList, setDocList] = useState(data?data:[])

    useEffect(()=>{
        console.log(data)
        setDocList(data?data:[])
    },[])

    useEffect(()=>{
        setDocList(data?data:[])
    },[data])

// const handleDownload = (e, file) => {
//   e.preventDefault();
  
//   const link = document.createElement('a');
//   link.href = MEDIA_URL + file.document_file;
//   link.download = true;
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

    // const handleUpdate=()=>{
    //     //need to add
    // }

    const handleDeleteWithConfirmation = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                );
            }
        });
    };
    return (
        <div className='physician-doc-container'>
            <div className='align-items-center row mx-0 px-3 ps-2 mt-3 mb-1'>
                <div className='doc-list-title col-4 ps-3'>Name</div>
                <div className='doc-list-title col-2'>Type</div>
                <div className='col-6' />
            </div>
            <div className={`mx-0 row p-2`}>
                {docList?.map((file, index) => (
                    <div className={`d-flex doc-list-data col-12 row ms-0 ps-1`} key={index}>
                        <div className='text col-4 pe-0'>{file.document_name}</div>
                        <div className="text col-3">{file.document_type}</div>
                        {/* <span className="col col-1" /> */}
                        <div className="text col-1 col-2">
                            <a
                                className='doc-view-btn btn'
                                href={MEDIA_URL + file.document_file}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <span>
                                    <IoEyeSharp size='0.8rem' />
                                </span>
                                &nbsp;View
                            </a>
                        </div>
                        <div className="text col-2 ps-2">
                            <a
                                className='doc-download-btn btn'
                                href={MEDIA_URL + file.document_file} 
                                download
                                // href="#"
                                // onClick={(e) => handleDownload(e, file)}
                                target='_blank'

                            >
                                <span>
                                    <BiSolidDownload size='0.8rem' />
                                </span>
                                &nbsp;Download
                            </a>
                        </div>
                        
                        <div className="text col d-flex justify-content-center">
                            {/* <div
                                className='doc-edit-btn btn'
                                onClick={()=>handleUpdate(file.id)}
                            >
                                <span>
                                    <TbPencil size='1rem' />
                                </span>
                            </div> */}
                            <div
                                className='doc-edit-btn btn'
                                onClick={()=>handleDeleteWithConfirmation(file.id)}
                            >
                                <span>
                                    <RiDeleteBin6Fill size='1rem' />
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
                <div className=''>

                </div>
            </div>
        </div>
  )
}

export default PhysicianDocList
