import React from "react";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import "../Core.css";
import "./Layout.css";
import BreadCrumb from "../../shared/breadcrumbs/BreadCrumb";

const Layout = () => {
  return (
    <div className='window-container mx-0 row'>
      <div className='col-2 px-2 mx-4'>
      <Sidebar />{/* SidebarContent goes here */}
      </div>
      <div className="col body-container px-0">
        <Header />
        {/* HeaderContent goes here */}
        <div className="page-container">
          <div className="breadcrumb-container">
            <BreadCrumb />
          </div>
          <Outlet />
          {/* PageContent goes here */}
        </div>
      </div>
      <Footer />
      {/* FooterContent goes here */}
    </div>
  );
};

export default Layout;
