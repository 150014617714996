import calender_icon from '../../../../../../../assets/pictures/icons/calender-icon.png'
import React, { useState } from 'react'
import InputTextBox from '../../../../../../shared/form-components/form-text/InputTextBox'
import MultiInputBox from '../../../../../../shared/form-components/multi-input/MultiInputBox'
import InputUploadBox from '../../../../../../shared/form-components/form-upload/InputUploadBox'
import { Form, Popover } from 'react-bootstrap'
import InputBtnGroup from '../../../../../../shared/form-components/form-text/InputBtnGroup'
import Calendar from 'react-calendar'
import moment from 'moment'
import CheckBoxBtn from '../../../../../../shared/form-components/checkbox/CheckBoxBtn'
import TextAreaBox from '../../../../../../shared/form-components/form-textarea/TextAreaBox'
import PhysicianSearchBox from '../../../../../../shared/form-components/search-box/PhysicianSearchBox'
import { FiSearch } from 'react-icons/fi'
import DropDownBox from '../../../../../../shared/form-components/dropdown/CustomDropDown'
import { useNavigate } from 'react-router-dom'

const PhysicianDetails = ({ value, setValue, functions }) => {
    const [showDOBCalendar, setShowDOBCalendar] = useState(false)
    const [showLicExpCalendar, setShowLicExpCalendar] = useState(false)
    const [showJoinDateCalendar, setShowJoinDateCalendar] = useState(false)
    const navigate = useNavigate()

    const handleDOBCalendar = (e) => {
        setValue?.setDOB(e)
        setShowDOBCalendar(false)
    }
    const handleJoinDateCalendar = (e) => {
        setValue?.setJoinedDate(e)
        setShowJoinDateCalendar(false)
    }
    const handleLicExpCalendar = (e) => {
        setValue?.setLicenseExpDate(e)
        setShowLicExpCalendar(false)
    }
    const dOBCalendar = (
        <Popover
            className='calendar-popup-container'
        >
            <Calendar onChange={handleDOBCalendar} value={value?.dOB} />
        </Popover>
    )

    const joinDateCalendar = (
        <Popover
            className='calendar-popup-container'
        >
            <Calendar onChange={handleJoinDateCalendar} />
        </Popover>
    )

    const licExpCalendar = (
        <Popover
            className='calendar-popup-container'
        >
            <Calendar onChange={handleLicExpCalendar} value={value?.licenseExpDate} />
        </Popover>
    )

    return (
        <div className='row mx-0'>
            <div className="col-6 page-title-label">Physician Details</div>
            <div className="col-3 ps-2 page-title-label">Personal Information</div>
            <div className="col-3 ps-2 page-title-label">
                <PhysicianSearchBox
                    handleData={functions?.handlePhysicianSearch}
                    placeholder={'Enter Physician Info'}
                    icon={
                        <div className="search-icon-btn mx-0" >
                        <span><FiSearch size='19px' /></span>
                        </div>
                    }
                />
            </div>
            <div className='left-split-container col-6 row'>
                <InputTextBox
                    label={'ID.'}
                    containerClass={'col-6 mb-3'}
                    onChange={setValue?.setPhysicianId}
                    defvalue={value?.physicianId}
                    disabled={true}
                    required={true}
                />
                <InputBtnGroup
                    label={'Joined Date'}
                    required={true}
                    icon={
                        <img
                            src={calender_icon}
                            alt=""
                            className='search-icon-img'
                        />
                    }
                    containerClass={'col-6 mb-3'}
                    defvalue={value?.joinedDate && moment(new Date(value?.joinedDate)).format("DD - MM - YYYY")}
                    setTogglePopup={setShowJoinDateCalendar}
                    togglePopup={showJoinDateCalendar}
                    popupContent={joinDateCalendar}
                    popupPlacement={"bottom"}
                    // type={'date'}
                />
                <DropDownBox
                    required={true}
                    label={'Title'}
                    containerClass={'col-4 mb-3'}
                    options={value?.titleList}
                    setDataValue={setValue?.setTitle}
                    selectedValue={value?.title}
                    fluid={true}
                    selection={true}
                />
                <InputTextBox
                    required={true}
                    label={'Name'}
                    containerClass={'col-8 mb-3'}
                    onChange={setValue?.setPhysicianName}
                    defvalue={value?.physicianName}
                />
                <InputTextBox
                    required={true}
                    label={'License No.'}
                    containerClass={'col-7 mb-3'}
                    onChange={setValue?.setLicenseNum}
                    defvalue={value?.licenseNum}
                    // type={'number'}
                />
                {value?.specialityList&&<DropDownBox
                    required={true}
                    label={'Speciality'}
                    containerClass={'col-5 mb-3'}
                    addNew={true}
                    fluid={true}
                    search={true}
                    selection={true}
                    setNew={functions?.addPhysicianSpeciality}
                    options={value?.specialityList}
                    setDataValue={setValue?.setSpeciality}
                    selectedValue={value?.speciality}
                />}
                <InputBtnGroup
                    required={true}
                    label={'License Expiry Date'}
                    icon={
                        <img
                            src={calender_icon}
                            alt=""
                            className='search-icon-img'
                        />
                    }
                    containerClass={'col-5 mb-3'}
                    defvalue={value?.licenseExpDate && moment(new Date(value?.licenseExpDate)).format("DD - MM - YYYY")}
                    setTogglePopup={setShowLicExpCalendar}
                    togglePopup={showLicExpCalendar}
                    popupContent={licExpCalendar}
                    popupPlacement={"bottom"}
                    // type={'date'}
                />
                <MultiInputBox
                    required={true}
                    label={'Qualification'}
                    data={value?.qualifications}
                    onAdd={functions?.addPhysicianQualifications}
                    onRemove={functions?.deleteQualification}
                    containerClass={'col-7 mb-3'}
                />
                <div className='col-12 row mx-0 px-3'>
                    <Form.Label className='label-text-content col-12 px-0'>
                        Upload Picture *
                    </Form.Label>
                    <div className='physician-photo-upload col-9 py-2'>
                        <InputUploadBox
                            containerClass={''}
                            height={'11.25rem'}
                            bgColor={'#F1F1F1'}
                            handleFile={setValue?.setImage}
                            fileValue={value?.image}
                            crop={true}
                            id={'physician-profile-picture'}
                        />
                    </div>
                    <div className='d-flex flex-column align-items-center justify-content-center col-3'>
                        <div className="row">
                            <label htmlFor='physician-profile-picture'>
                                <div className='upload-browse-btn btn my-1'>
                                    Browse
                                </div>
                            </label>
                        </div>
                        <div className="row">
                            <div className='upload-clear-btn btn my-1' onClick={()=>setValue?.setImage('')}> 
                                Clear
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='col-6 row ms-2 ps-3 pe-0 me-0'>
                <InputTextBox
                    required={true}
                    label={'Email'}
                    type={'email'}
                    containerClass={'col-6 mb-3'}
                    onChange={setValue?.setEmail}
                    defvalue={value?.email}
                />
                <InputTextBox
                    required={true}
                    label={'Mobile'}
                    type={'number'}
                    containerClass={'col-6 mb-3'}
                    onChange={setValue?.setMobileNumber}
                    defvalue={value?.mobileNumber}
                />
                <InputBtnGroup
                    required={true}
                    label={'Date Of Birth'}
                    icon={
                        <img
                            src={calender_icon}
                            alt=""
                            className='search-icon-img'
                        />
                    }
                    containerClass={'col-6 mb-3'}
                    defvalue={value?.dOB && moment(new Date(value?.dOB)).format("DD MMM YYYY")}
                    setTogglePopup={setShowDOBCalendar}
                    togglePopup={showDOBCalendar}
                    popupContent={dOBCalendar}
                    popupPlacement={"bottom"}
                    // type={'date'}
                />
                <CheckBoxBtn
                    label={'Gender'}
                    required={true}
                    content={'Male'}
                    value={'male'}
                    setSelected={setValue?.setGender}
                    selected={value?.gender}
                    containerClass={'col-2 mb-3 age-form-container1'}
                />
                <CheckBoxBtn
                    content={'Female'}
                    value={'female'}
                    setSelected={setValue?.setGender}
                    selected={value?.gender}
                    containerClass={'col-2 mb-3 mt-1 age-form-container2'}
                />
                <CheckBoxBtn
                    content={'Other'}
                    value={'other'}
                    setSelected={setValue?.setGender}
                    selected={value?.gender}
                    containerClass={'col-2 mb-3 mt-1 age-form-container3'}
                />
                <InputTextBox
                    required={true}
                    label={'City'}
                    containerClass={'col-6 mb-3'}
                    onChange={setValue?.setCity}
                    defvalue={value?.city}
                />
                <InputTextBox
                    required={true}
                    label={'Pincode'}
                    containerClass={'col-6 mb-3'}
                    onChange={setValue?.setPincode}
                    defvalue={value?.pincode}
                    // type={'number'}
                />
                <InputTextBox
                    required={true}
                    label={'District'}
                    containerClass={'col-4 mb-3'}
                    onChange={setValue?.setDistrict}
                    defvalue={value?.district}
                />
                <InputTextBox
                    required={true}
                    label={'State'}
                    containerClass={'col-4 mb-3'}
                    onChange={setValue?.setState}
                    defvalue={value?.state}
                />
                <InputTextBox
                    required={true}
                    label={'Country'}
                    containerClass={'col-4 mb-3'}
                    onChange={setValue?.setCountry}
                    defvalue={value?.country}
                />
                <TextAreaBox
                    required={true}
                    label={'Address Line 1'}
                    rows={'2'}
                    containerClass={'col-12 mb-3'}
                    onChange={setValue?.setAddress1}
                    defvalue={value?.address1}
                />
                <TextAreaBox
                    required={true}
                    label={'Address Line 2'}
                    rows={'2'}
                    containerClass={'col-12 mb-3'}
                    onChange={setValue?.setAddress2}
                    defvalue={value?.address2}
                />
                <span className='col-6 mt-4' />
                <div className="col-6 mt-4 row d-flex justify-content-end me-0 pe-0">
                    <div
                        className='form-cancel-btn btn col-6 me-3'
                        onClick={()=>navigate('/physician-list')}
                    >
                        Cancel
                    </div>
                    <div
                        className='form-next-btn btn col-6 me-0'
                        onClick={functions?.handlePhysicianRegister}
                    >
                        Save & Next
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PhysicianDetails
