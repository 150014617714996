import React from 'react'
import { MEDIA_URL } from '../../axios'

const useGetListDatas = () => {
    const getDropdownList = (data, value_data, label_data) =>{
        var templist = []
        data.map((item)=>{
            templist.push(
                {
                    label: label_data?item?.[label_data]:item,
                    value: value_data?item?.[value_data]:item
                }
            )
        })

        return templist
    }

    const getMultiInputList = (data, id_data, value_data) =>{
        let list=[]
        data.map((item)=>{
            list.push(
                {
                    id: id_data?item?.[id_data]:item,
                    value: value_data?item?.[value_data]:item
                }
            )
        })

        return list
    }

    const getSemanticOptionsList = (data, id_data, value_data, sub_data, content_data, icon_data, avatar) =>{
        let list=[]
        data.map((item)=>{
            list.push(
                {
                    value: id_data?item?.[id_data]:null,
                    text: value_data?item?.[value_data]:null,
                    image: icon_data?{ 'avatar': avatar, src:MEDIA_URL+item?.[icon_data]}:null,
                    description: sub_data?item?.[sub_data]:null,
                    content: content_data?item?.[content_data]:null
                }
            )
        })

        return list
    }

  return {
    getDropdownList,
    getMultiInputList,
    getSemanticOptionsList
  }
}

export default useGetListDatas
