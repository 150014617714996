import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { MEDIA_URL } from "../../../../../axios";
import { Image } from "antd";
import sampleImg from "../../../../../../assets/logos/doc-sample.png"
const PhysicianForm = ({ physicianData }) => {
  console.log("abcedefghio", physicianData.addressLine1);
  return (
    <div
      className="container-fluid"
      style={{ height: "400px", overflow: "scroll" }}
    >
      <div className="row">
        {/* Left Side Form */}
        <div className="col-md-6">
          <div
            className="card mb-3 mt-0 p-3"
            style={{
              backgroundColor: "#fffaf4",
              border: "none",
              borderRadius: "15px",
            }}
          >
            <div className="d-flex align-items-center mb-2">
              <img
                src={physicianData?.image ? MEDIA_URL + physicianData?.image:sampleImg}
                alt="Profile"
                className="rounded-circle me-3"
                style={{
                  width: "60px",
                  height: "60px",
                  objectFit: "cover",
                }}
              />
              <div className="d-flex flex-column align-items-start">
                <h6 className="text-primary" style={{ fontSize: "14px" }}>
                  <span
                    className="me-2"
                    style={{ color: "#333", fontSize: "14px" }}
                  >
                    ID :
                  </span>{" "}
                  {physicianData.id || "N/A"}
                </h6>
                <h6 className="fw-bold mb-2" style={{ fontSize: "18px" }}>
                  {physicianData.basicInfo?.full_name || "N/A"}
                </h6>
              </div>
            </div>

            <div
              className="bg-white p-3 rounded"
              style={{
                borderRadius: "10px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="row">
                <div className="col-6">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                    Qualification :
                  </p>
                </div>
                <div className="col-6 text-end">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                  {physicianData.Qualifications &&
                  physicianData.Qualifications.length > 0 ? (
                    physicianData.Qualifications.map((qualification, index) => (
                      <button
                        key={index}
                        className=""
                        disabled
                      >
                        {qualification}
                      </button>
                    ))
                  ) : (
                    <span>N/A</span>
                  )}
                  </p>
                </div>
                <div className="col-6">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                    Speciality :
                  </p>
                </div>
                <div className="col-6 text-end">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                    {physicianData.specialtyType || "N/A"}
                  </p>
                </div>
                <div className="col-6">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                    Department :
                  </p>
                </div>
                <div className="col-6 text-end">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                    {physicianData.department || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h5>Physician Details</h5>
            <div className="row mb-3">
              <div className="col">
                <label>ID</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.id || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>Joined Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    moment(physicianData.joinedDate).format("MMMM D, YYYY") ||
                    ""
                  }
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.title || "N/A"}
                  readOnly
                />
              </div>
              <div className="col">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.basicInfo?.full_name || ""}
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Licence No.</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.licenseNo || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>Speciality</label>
                <select className="form-select" disabled>
                  <option>{physicianData.specialtyType || "Select"}</option>
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Licence Expiry Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    moment(physicianData.licenseExpiryDate).format(
                      "MMMM D, YYYY"
                    ) || ""
                  }
                  readOnly
                />
              </div>
              <div className="col">
                <label>Qualification</label>
                <div className="d-flex">
                  {physicianData.Qualifications &&
                  physicianData.Qualifications.length > 0 ? (
                    physicianData.Qualifications.map((qualification, index) => (
                      <button
                        key={index}
                        className="btn btn-outline-primary me-2"
                        disabled
                      >
                        {qualification}
                      </button>
                    ))
                  ) : (
                    <span>N/A</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side Form */}
        <div className="col-md-6">
          <div>
            <h5>Personal Information</h5>
            <div className="row mb-3">
              <div className="col">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={physicianData.email || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.mobile || ""}
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Date Of Birth</label>
                <input
                  type="text"
                  className="form-control"
                  value={moment(physicianData.dob).format("MMMM D, YYYY") || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label className="form-label">Gender *</label>
                <div className="btn-group" role="group" aria-label="Gender">
                  <input
                    type="radio"
                    className="btn-check"
                    name="gender"
                    id="male"
                    value="male"
                    checked={physicianData.gender === "male"}
                  />
                  <label className="btn btn-outline-secondary" htmlFor="male">
                    Male
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="gender"
                    id="female"
                    value="female"
                    checked={physicianData.gender === "female"}
                  />
                  <label className="btn btn-outline-secondary" htmlFor="female">
                    Female
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="gender"
                    id="other"
                    value="other"
                    checked={physicianData.gender === "other"}
                  />
                  <label className="btn btn-outline-secondary" htmlFor="other">
                    Other
                  </label>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Country</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.country || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>State</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.state || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>District</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.district || ""}
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.city || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>PIN Code</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.pincode || ""}
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Address Line 1</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.addressLine1 || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>Address Line 2</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.addressLine2 || ""}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Upload Document */}

      <div className="col-md-6">
        <div>
          <h5>Upload Document</h5>
          <div className="mb-3 col-4">
            <label>Document Type</label>
            <input
              type="text"
              className="form-control mt-1"
              value={physicianData.docType?.document_type || ""}
              readOnly
            />
          </div>
          <div className="col-4">
            <label>Document</label>
            {physicianData &&
            physicianData.docType &&
            physicianData.docType.document_file ? (
              <Image
                src={MEDIA_URL + physicianData.docType.document_file}
                alt="physician Document"
                className="document-image mt-1"
                height={100}
                width={100}
              />
            ) : (
              <p>No document available</p>
            )}
          </div>
        </div>
      </div>

      {/* Consultation Timings */}
      <div className="container mt-4">
        <h5>Consultation</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="bg-light">
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Day</th>
              </tr>
            </thead>
            <tbody>
              {physicianData.Consultation &&
              physicianData.Consultation.length > 0 ? (
                physicianData.Consultation.map((item) => {
                  return (
                    <>
                      <tr key={item.id}>
                        <td>{item.from_time || ""}</td>
                        <td>{item.to_time || ""}</td>
                        <td>{item.day}</td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <span>N/A</span>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PhysicianForm;
