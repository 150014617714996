import React, { useState } from 'react'
import { Nav, Tab, TabPane } from 'react-bootstrap'
import './Settings.css'
import IDConfig from './components/tabpanes/IDConfig'
import TokenSettings from './components/tabpanes/TokenSettings'
import VitalTypeSettings from './components/tabpanes/VitalTypeSettings'

const Settings = () => {
    const [key, setKey] = useState("tab1")
    return (
        <div className='row '>
            <Tab.Container className="" activeKey={key} onSelect={(k) => setKey(k)}>
                <div className="col-10">
                    <div className='pagebody-container'>
                        <Tab.Content className='tabcontent-container'>
                            <TabPane
                                eventKey='tab1'
                                className=''
                            >
                                <IDConfig 
                                
                                />
                            </TabPane>
                            <TabPane
                                eventKey='tab2'
                                className=''
                            >
                                <TokenSettings 
                                
                                />
                            </TabPane>
                            <TabPane
                                eventKey='tab3'
                                className=''
                            >
                                <VitalTypeSettings 
                                
                                />
                            </TabPane>
                        </Tab.Content>
                    </div>
                </div>
                <div className='col-2'>
                    <Nav className='settings-tabpane-container'>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='settings-tabpane-item'
                                eventKey='tab1'
                            >
                                ID Configuration
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='settings-tabpane-item'
                                eventKey='tab2'
                            >
                                Token Settings
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='settings-tabpane-item'
                                eventKey='tab3'
                            >
                                Vital Types
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </Tab.Container >
        </div>

    )
}

export default Settings
