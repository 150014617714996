import React, { useRef, useState } from "react";
import upload_img from "../../../../assets/pictures/icons/upload-icon.png";
import check from "../../../../assets/pictures/images/check.png";
import "./InputUpload.css";
import { Modal } from "react-bootstrap";
import { Cropper } from "react-cropper";

const InputUploadBox = (props) => {
  const {
    containerClass,
    width,
    height,
    bgColor,
    acceptData,
    multiple,
    id,
    handleFile,
    fileValue,
    crop,
  } = props;
  const [showCrop, setShowCrop] = useState(false);
  const [image, setImage] = useState("");
  const cropperRef = useRef();

  const tempWidth = "100%";
  const tempHeight = "15.625rem";

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleDrop(e) {
    e.preventDefault();
    var files = e.dataTransfer.files;
    var fileInput = e.target;
    fileInput.files = files;
    handleFileSubmit(e);
  }

  const handleFileSubmit = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      handleFile(undefined);
      return;
    }
    if (multiple && !crop) {
      const chosenFiles = e.target.files;
      const uploaded = [];
      var i = 0;
      while (i !== chosenFiles.length) {
        if (uploaded.findIndex((f) => f.name === chosenFiles[i].name) === -1) {
          uploaded.push(chosenFiles[i]);
        }
        i = i + 1;
      }
      handleFile(uploaded);
    } else {
      if (crop) {
        const reader = new FileReader();
        reader.onload = (r) => {
          setImage(r.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        setShowCrop(true);
      } else {
        handleFile(e.target.files[0]);
      }
    }
  };

  const getCropData = async () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
      const croppedDataUrl = croppedCanvas.toDataURL(); // Get the cropped image as a Data URL
      await fetch(croppedDataUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const fileData = new File([blob], "profile.png", { type: blob.type });
          if (fileData.name !== "") {
            handleFile(fileData);
            setShowCrop(false);
          }
        });
    }
  };

  return (
    <>
      <Modal
        show={showCrop}
        onHide={() => setShowCrop(false)}
        centered={true}
        size="xl"
        contentClassName="custom-modal"
      >
        <Modal.Header closeButton className="custom-modal-header">
          Crop Profile Image
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <div className="custom-modal-content row mx-0">
            <div className="label-text-content col-6 fs-5 my-2 text-center">
              Editor
            </div>
            <div className="label-text-content col-6 fs-5 my-2 text-center">
              Preview
            </div>
            <div className="image-editor-container col-6 d-flex align-items-center">
              <Cropper
                ref={cropperRef}
                style={{
                  width: "100%",
                  height: "300px", // Fixed height for the cropper area
                }}
                zoomTo={0}
                initialAspectRatio={1}
                aspectRatio={1} // Aspect ratio fixed to 1:1
                preview=".img-preview"
                src={image}
                viewMode={1}
                dragMode="move" // Allow dragging within the crop box
                minCropBoxHeight={300} // Fixed crop box height
                minCropBoxWidth={300} // Fixed crop box width
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={false} // No guides
              />
            </div>
            <div className="image-preview-container col-6  d-flex align-items-center">
              <div
                className="box d-flex justify-content-center"
                style={{
                  width: "100%",
                  float: "right",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <div
                  className="img-preview"
                  style={{ width: "100%", float: "left", height: "300px" }}
                />
              </div>
            </div>
            <div className="col-12 row mx-0 my-3">
              <span className="col-9" />
              <div className="col-1 col-2">
                <div
                  className="crop-cancel-btn btn"
                  onClick={() => {
                    handleFile("");
                    setShowCrop(false);
                  }}
                >
                  Cancel
                </div>
              </div>
              <div className="col-1 col-2">
                <div className="crop-submit-btn btn" onClick={getCropData}>
                  Crop
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <label
        className="col-12"
        htmlFor={id}
        onDragOver={(e) => handleDragOver(e)}
        onDrop={(e) => handleDrop(e)}
      >
        <div
          className={`col-12 input-upload-control ${containerClass}`}
          style={{
            width: width ? width : tempWidth,
            height: height ? height : tempHeight,
            background: bgColor ? bgColor : "#fff",
          }}
        >
          <div className="text-center">
            <img
              src={fileValue ? check : upload_img}
              className="upload-dummy-img mb-3"
              alt=""
            />
            <br />
            {!fileValue
              ? "Drag & Drop File Here or Click to Upload"
              : "File Uploaded Successfully"}
          </div>
        </div>
      </label>
      <input
        id={id}
        type="file"
        multiple={multiple ? multiple : false}
        accept={crop ? "image/*" : acceptData ? acceptData : "image/*, .pdf"}
        onChange={(e) => handleFileSubmit(e)}
        hidden
      />
    </>
  );
};

export default InputUploadBox;
