import React, { useEffect, useState } from "react";
import physician_list from "../../../../../assets/pictures/icons/physician_details-icon1.png";
import physician_add from "../../../../../assets/pictures/icons/physician-add-icon.png";
import { Nav, Tab, TabPane } from "react-bootstrap";
import "./PhysicianList.css";
import PhysicianListCard from "./components/PhysicianListCard";
import usePhysicianServices from "../../../../services/api-services/physicianServices";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import FilterSortPagination from "../../../reception/patient/patient-list/components/FilterSortPagination";
import { Button, Modal } from "antd";
import PhysicianForm from "./components/PhysicianForm";
import moment from "moment";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HashLoader from "react-spinners/ClockLoader";
const PhysicianList = () => {
  const [physicianList, setPhysicianList] = useState();
  const [singlePhysician, setSinglePhysician] = useState({});
  const [pid, setPid] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    item: 10,
    totalPages: 1,
  });
  const [sortBy, setSortBy] = useState("");
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [fromDate, setFromDate] = useState(""); // New state for range start date
  const [toDate, setToDate] = useState(""); // New state for range end date
  const[response,setResponse] = useState(false)
  const [loading,setLoading] = useState(false)

  const navigate = useNavigate();

  const { getPhysicianList, getSinglePhysician, getSortFilterPagination } =
    usePhysicianServices();

  const getPhysicianListData = async () => {
    setLoading(true); // Start loading
    try {
      const response = await getSortFilterPagination({
        item: pagination.item,
        page: pagination.page,
        sort_by: sortBy,
        search: search,
        type: filtered,
        date: selectedDate,
        from_date: fromDate, // Send fromDate to API
        to_date: toDate, // Send toDate to API
      });
      if (response?.success) {
        setPhysicianList(response?.data);
        setPagination((prev) => ({
          ...prev,
          totalPages: response?.data?.total_page,
        }));
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (error){
      console.log(error)
    }finally {
      setLoading(false); // Stop loading
    }
  };
  console.log(physicianList);

  useEffect(() => {
    getPhysicianListData();
  }, [
    pagination.page,
    sortBy,
    search,
    filtered,
    selectedDate,
    fromDate,
    toDate,
  ]); // Trigger API call whenever the page changes

  const getSinglePhysicianData = async (ID) => {
    try {
      console.log(ID);
      const response = await getSinglePhysician(ID);
      console.log("resssssss", response);

      if (response?.success) {
        const mappedData = {
          id: response?.data?.employee_id || "",
          medicalId: response?.data?.medical_id || {},
          basicInfo: response?.data?.basic_info || {},
          addressLine1: response?.data?.address_line_1 || "",
          addressLine2: response?.data?.address_line_2 || "",
          country: response?.data?.country || "",
          city: response?.data?.city || "",
          state: response?.data?.state || "",
          district: response?.data?.district || "",
          pincode: response?.data?.pincode || "",
          email: response?.data?.basic_info?.email || "",
          mobile: response?.data?.basic_info?.mobile || "",
          dob: response?.data?.dob || "",
          employeeId: response?.data?.employee_id || "",
          gender: response?.data?.gender || "",
          joinedDate: response?.data?.medical_id?.joined_date || "",
          licenseExpiryDate:
            response?.data?.medical_id?.license_expiry_date || "",
          licenseNo: response?.data?.medical_id?.license_no || "",
          specialtyType: response?.data?.medical_id?.speciality_type || "",
          title: response?.data?.medical_id?.title || "",
          physicianToken: response?.data?.physician_token || {},
          image: response?.data?.basic_info?.image || "",
          Qualifications: response?.data?.qualifications || [],
          Consultation: response?.data?.physician_shift || [],
          docType: response?.data?.document[0] || [],
        };
        setSinglePhysician(mappedData);
        console.log("singleResponse", response);
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("singlePHY", singlePhysician);

  useEffect(() => {
    if (pid) {
      getSinglePhysicianData(pid);
      setModalOpen(true);
    }
  }, [pid]);

  const onCardClick = (cardId) => {
    setPid(cardId);
  };

  console.log("pass", singlePhysician);

  const handleModalClose = () => {
    setModalOpen(false);
    setSinglePhysician({});
    setPid("");
  };

  const filterOptions = [
    // { label: "Native", value: "native" },
    // { label: "Foriegner", value: "foriegner" },
    // { label: "Register Date", value: "register date" },
  ];

  const sortOptions = [
    {
      label: (
        <>
          Physician Id
          <span className="ps-2">
            <ArrowUpwardIcon />
          </span>
        </>
      ),
      value: "physician_id_asc",
    },
    {
      label: (
        <>
          Physician Id
          <span className="ps-2">
            <ArrowDownwardIcon />
          </span>
        </>
      ),
      value: "physician_id_desc",
    },
    {
      label: <>Name : A-Z</>,
      value: "name_asc",
    },
    {
      label: <>Name : Z-A</>,
      value: "name_desc",
    },
    {
      label: (
        <>
          Joining Date
          <span className="ps-2">
            <ArrowUpwardIcon />
          </span>
        </>
      ),
      value: "join_date_asc",
    },
    {
      label: (
        <>
          Joining Date
          <span className="ps-2">
            <ArrowDownwardIcon />
          </span>
        </>
      ),
      value: "join_date_desc",
    },
  ];

  const handleFilterChange = (filter) => {
    console.log("Filter selected:", filter);
    setFiltered(filter); // Update the filtered state with the selected filter
    setFromDate(""); 
    setToDate(""); 
    setSelectedDate("")
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    console.log("Search query:", query);
    setSearch(query);
    // Implement search logic here
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      console.log("Formatted Date:", formattedDate);
      setSelectedDate(formattedDate);
      setFiltered("");
      setFromDate("");
      setToDate("");
    } else {
      setSelectedDate(""); // Clear the selected date if no date is selected
    }
  };

  const handleRangeDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setFromDate(moment(start).format("YYYY-MM-DD")); // Update the fromDate state
      setToDate(moment(end).format("YYYY-MM-DD")); // Update the toDate state
      setSelectedDate(""); // Clear the selected date
      setFiltered(""); // Clear any selected filter
    } else {
      setFromDate("");
      setToDate("");
    }
  };

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy); // Update the sort_by state
  };

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  const onhandleReset = async () => {
    // Reset all states to their initial values
    setPagination({
      page: 1,
      item: 10,
      totalPages: 1,
    });
    setSortBy("");
    setSearch("");
    setFiltered("");
    setSelectedDate("");
    setFromDate("");
    setToDate("");
    setResponse(true)
    // Fetch the initial patient list data
    getPhysicianListData();
  };

  return (
    <div className="col">
      <Tab.Container activeKey={"tab1"}>
        <div className="col-12">
          <Nav className="tabpane-container">
            <Nav.Item className="content">
              <Nav.Link className="tabpane-item" eventKey="tab1">
                <img
                  src={physician_list}
                  className="physician-details-icon"
                  alt=""
                />
                &nbsp;&nbsp; Physician List
              </Nav.Link>
            </Nav.Item>
            <div className="d-flex justify-content-end col pe-5">
              <div
                className="tabpane-extra-btns"
                onClick={() => navigate("/physician-register")}
              >
                <img
                  src={physician_add}
                  className="physician-add-icon"
                  alt=""
                />
                &nbsp;&nbsp; Add Physician
              </div>
            </div>
          </Nav>
        </div>
        {/* <FilterSortPagination /> */}
        <FilterSortPagination
          filterOptions={filterOptions}
          sortOptions={sortOptions}
          onFilterSelect={handleFilterChange}
          onSortSelect={handleSortChange}
          onSearchChange={handleSearchChange}
          onPageChange={handlePageChange}
          pagination={pagination}
          handleDateChange={handleDateChange} // Ensure this is passed down
          handleRangeDateChange={handleRangeDateChange} // Date range
          handleReset={onhandleReset}
          response={response}
          setResponse={setResponse}
          search={search}
        />
        <div className="patient-list-container">
          <Tab.Content className="">
            <TabPane eventKey="tab1" className="">
              <div className="container-fluid mx-0 px-0 pe-5">
                <div className="row mx-0 w-100">
                {loading ? (
              
                  <div className="d-flex justify-content-center align-items-center loader-container">
                    <HashLoader color="#e3b075" size={100} />
                  </div>
                  ) :
                  <>
                  {physicianList?.physicians?.length > 0 &&
                  physicianList?.physicians?.map((data, index) => (
                  <PhysicianListCard
                  index={index}
                  data={data}
                  functions={onCardClick}
                  getPhysician={getPhysicianListData}
                  />
                ))}
                </>
                }
                </div>
              </div>
            </TabPane>
          </Tab.Content>
        </div>
      </Tab.Container>
      <Modal
        title="Physician Details"
        centered
        open={modalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        width={1000} // Adjust the modal width as needed
        height={400}
        footer={null}
      >
        <PhysicianForm physicianData={singlePhysician} />
      </Modal>
    </div>
  );
};

export default PhysicianList;
