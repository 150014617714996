import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import './BreadCrumb.css'
import { useLocation } from 'react-router-dom'
import dashimg from '../../../assets/pictures/icons/svg/jam_menu.svg'
const BreadCrumb = () => {
  const location = useLocation()
  const [breadCrumb, setBreadCrumb] = useState(["Dashboard"])
  const crumbs = {
    "":["Dashboard"],
    patientregister: ["Patient", "Patient List", "Patient Registration"],
    patientlist: ["Patient", "Patient List"],
    patientcardgenerate: ['Patient', 'Generate Card'],
    physicianregister:['Physician', 'Physician List', 'Physician Registration'],
    physicianlist:['Physician', 'Physician List'],
    appointmentconsultation:['Appointment', 'Consultation'],
  }
  useEffect(() => {
    let current = location.pathname.slice(1).split("-").join("");
    console.log(current)
    setBreadCrumb(crumbs[current])
  }, [location.pathname])
  return (
    <Breadcrumb className='breadcrumb-list'>
      {breadCrumb && breadCrumb.map((crumb, index) => {
        return (
          index !== breadCrumb.length-1?
          
          <Breadcrumb.Item key={index} href="#">
            {crumb === 'Dashboard' && (
            <img 
              src={dashimg} 
              alt="Dashboard Icon" 
              style={{ marginRight: '5px'}} 
            />
          )}{crumb||'Dashboard'}</Breadcrumb.Item>
          :
          <Breadcrumb.Item key={index} active href="#"> {crumb === 'Dashboard' && (
            <img 
              src={dashimg} 
              alt="Dashboard Icon" 
              style={{ marginRight: '5px'}} 
            />
          )}{crumb||'Dashboard'}</Breadcrumb.Item>

        )
      }
      )}

      {/* <Breadcrumb.Item href="#">Patients</Breadcrumb.Item>
        <Breadcrumb.Item active>Patient Registration</Breadcrumb.Item> */}
    </Breadcrumb>
  )
}

export default BreadCrumb
