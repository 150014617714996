import React from 'react'

const SubPrescription = ({data}) => {
 
  return (
    <div >
        <label className='mb-2 fw-bold h6'>Prescription</label>
        <div className='presc-table my-2'>
              <table className='table' style={{"text-align":"center"}}>
                <tr>
                  <th>Sl.No</th>
                  <th>Medicine Name</th>
                  <th>Dose</th>
                  <th>Days</th>
                </tr>
                {data?.map((d,i)=>{
                  return (
                  <tr >
                    <td>{i+1}</td>
                    <td>{d.medicine_name}</td>
                    <td>{d.dose}</td>
                    <td>{d.days}</td>
                  </tr>
                  )
                })}
              </table>  
                
            </div>  
    </div>
  )
}

export default SubPrescription