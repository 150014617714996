import React, { useState } from 'react'

const TreatmentHistoryList = (props) => {
    const { 
        data,
        onAdd,
        onRemove
    } = props
    const [addShow, setAddShow] = useState(false)
    const [content, setContent] = useState('')
    const [item, setItem] = useState('')

    function handleKeyDown(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        onAdd(content)
        e.target.value = ''
        setContent('')
        setAddShow(false)
    }

    function removeTag() {
        onRemove(item)
        setItem('')
    }
    return (
        <div className={`treatment-history-container row mx-0 `}>
            <div className="treatment-history-content col-11 row mx-0">
                <div className="content col">
                    {data&&data.map((content, index) => (
                        <div className={`treatment-history-data ${item===content.id&&'highlight'}`} key={index} onClick={() => setItem(content.id)}>
                            <div className="number">{index+1}</div>
                            <div className="text">{content.value}</div>
                        </div>
                    ))}
                    {addShow&&<textarea
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setContent(e.target.value)}
                        value={content}
                        rows={'2'}
                        className="treatment-history-input"
                        placeholder={'Type Here ...'}
                    />}

                </div>
                <div className="buttons col">
                    <div
                        className='treatment-remove-btn btn my-2'
                        onClick={removeTag}
                    >
                        -
                    </div>
                    <div 
                        className='treatment-add-btn btn'
                        onClick={()=>setAddShow(!addShow)}
                    >
                        +
                    </div>
                </div>
            </div>
            <div
                className='col-1 d-flex justify-content-end px-0'
            >
                <div
                    className='icon-input-btn btn'
                >
                    -
                </div>
            </div>
        </div>
    )
}

export default TreatmentHistoryList
