import React from 'react'
import image1 from '../../../../assets/pictures/doc-sample.png'

const PhysicianTable = () => {
    const physicians = [
        { id: '1', name: 'Dr. Sreekanth', status: 'Available', image: image1 },
        { id: '2', name: 'Dr. John', status: 'Available', image: image1 },
        { id: '3', name: 'Dr. Smith', status: 'Available', image: image1 },
        { id: '4', name: 'Dr. Jane', status: 'Available', image: image1 },
        { id: '5', name: 'Dr. Doe', status: 'Available', image: image1 }
      ];
  return (
    <div>
        {physicians.map((physician, index) => (
        <div key={index} className='row physician-box mb-1 pt-2 pb-3'>
            <div className='col-3 object-fit p-0 ps-3 mb-2'>
            <img src={physician.image} alt={physician.name}  style={{ width: "4rem", height: "4rem" }}/>
            </div>
            <div className='col-6 d-flex flex-column justify-content-center ps-5'>
            <div>ID: <span className='text-primary'>{physician.id}</span></div>
            <div><strong>{physician.name}</strong></div>
            </div>
            <div className='col-3 d-flex justify-content-center align-items-center'> 
            <div className={`bg-light p-2 px-3 rounded text-center`} style={{color:"#58F147" , filter:"drop-shadow(0 0 0.04rem #8E8E8E)"}}>
                {physician.status}
            </div>
            </div>
        </div>
        ))}
    </div>
  )
}

export default PhysicianTable