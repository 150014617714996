import React, { useEffect, useState } from 'react'
import useConsultationServices from '../../../../../services/api-services/consultationServices'

  const SubHealthAssessment = ({data}) => {
    const [vitalTypes,setVitalTypes] = useState([])

    const { getVitalTypes }=useConsultationServices()

    const getVitalTypeDetails = async ()=>{
      try{
          const response= await getVitalTypes()
          if(response?.success){
              setVitalTypes(response?.data?.filter((item)=>item.active===true))
          }
      }catch(err){
          console.log(err.response)
      }
  }
  useEffect(()=>{
    getVitalTypeDetails()
  },[])

  return (
    <div>
      <label className='mb-2 fw-bold h6'>Health Assessment</label>
      {vitalTypes&&vitalTypes?.map((item)=>{
            const subData = data?.filter((item2)=>item2.type===item.id)
            const temp =subData.length>0?true:false
            return(
      <div className='row mx-0 assessment-row'>
        <div className='assessment-main1 col-3 px-0 mx-0'>{item?.vital_name.toUpperCase()}</div>
        <div className='col-9 row mx-0 pe-0'>
        {temp?subData&&subData?.map((content)=>{
          return(
            <>
              <div className='col-3 px-0 mb-1'>
              <div className='assessment-sub2'>
              {content?.attribute}
              </div>
              </div>
              <div className='col-9 pe-0 mb-1'>
                <div className='assessment-desc1 pe-0'>
                {content?.description}
                </div>
              </div>
            </>
          )
        }):
        <>
            <div className='col-3 px-0 mb-1'>
                <div className='assessment-sub2'>
                    --
                </div>
            </div>
            <div className='col-9 pe-0 mb-1'>
                <div className='assessment-desc1 pe-0'>
                    --
                </div>
            </div>
        </>
        }  

        </div>
        <div className='col-10 mt-2'></div>

        
      </div>
      ) 
    })}
    </div>
  )
}

export default SubHealthAssessment