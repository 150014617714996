import React from "react";
import "./patientform.css"; // Import your custom styles here
import { MEDIA_URL } from "../../../../../axios";
import moment from "moment";
import { Image } from "antd";

const PatientForm = ({ patientData }) => {
  return (
    <div className="container mt-4 p-1 m-0 m-auto mx-2 text-dark text-opacity-75">
      <form>
        {/* Patient Details */}
        <div className="row mb-3">
          <h5>Patient Details</h5>
          <div className="col-md-4">
            <label>Mobile Number</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.mobileNumber}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>First Name</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.firstName}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.lastName}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Date of Birth</label>
            <input
              type="date"
              className="form-control mt-1"
              value={patientData?.dOB}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Age</label>
            <input
              type="text"
              className="form-control mt-1"
              value={`${patientData?.ageYear} years ${patientData?.ageMonth} months ${patientData?.ageDate} days`}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Gender</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.gender}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Email</label>
            <input
              type="email"
              className="form-control mt-1"
              value={patientData?.email}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>UIN</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.uIN}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Address Line 1</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.address1}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Address Line 2</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.address2}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>City *</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.city}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Pincode</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.pincode}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>State</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.state}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>District</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.district}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Country</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.country}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Admission Date</label>
            <input
              type="date"
              className="form-control mt-1"
              value={patientData?.admDate}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Admission Time</label>
            <input
              type="time"
              className="form-control mt-1"
              value={patientData?.admTime}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>File Number</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.fileNum}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Casualty</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.casualty == true ? "Yes" : "No"}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Patient Type</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.patientType}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Referred Type</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.refferedType}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Mobile Number</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.emgContName}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Emergency Contact Number</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.emgContNumber}
              readOnly
            />
          </div>
        </div>
        {/* Document Category */}
        <div className="row mb-3">
          <h5 className="fs-5">Passport & Records</h5>
          <div className="col-md-4">
            <label>Passport Number</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.passportNo}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Date of Expiry</label>
            <input
              type="text"
              className="form-control mt-1"
              value={moment(patientData?.passExpiry).format("DD-MM-YYYY")}
              readOnly
            />
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label>Passport</label>
            {patientData.docFile ? (
              <Image
                src={MEDIA_URL + patientData.docFile}
                alt="Patient Document"
                className="document-image mt-1"
                height={100}
                width={100}
              />
            ) : (
              <p>No document available</p>
            )}
          </div>
          <div className="col-md-4 mt-3">
            <label>Visa Type</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.visaType}
              readOnly
            />
          </div>
          <div className="col-md-4 mt-3">
            <label>Validity Period</label>
            <input
              type="text"
              className="form-control mt-1"
              value={moment(patientData?.validityPeriod).format("DD-MM-YYYY")}
              readOnly
            />
          </div>

          {/* <div className="col-md-4">
            <label>Issued Date</label>
            <input
              type="text"
              className="form-control mt-1"
              value={moment(patientData?.issuedDate).format("DD-MM-YYYY")}
              readOnly
            />
          </div> */}
          <div className="col-md-4 d-flex flex-column mt-3">
            <label>Visa</label>
            {patientData.visaDoc ? (
              <Image
                src={MEDIA_URL + patientData.visaDoc}
                alt="Patient Document"
                className="document-image mt-1"
                height={100}
                width={100}
              />
            ) : (
              <p>No document available</p>
            )}
          </div>

          <div className="col-md-4">
            <label>Purpose of Visit</label>
            <textarea
              type="text"
              className="form-control mt-1"
              value={patientData?.visitPurpose}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Remarks</label>
            <textarea
              type="text"
              className="form-control mt-1"
              value={patientData?.Remarks}
              readOnly
            />
          </div>
        </div>
        {/* Medical Records */}
        <div className="medical-records m-0">
          <h5 className="fs-5">Medical Records</h5>

          {/* Previous Medical Issues */}
          <div className="previous-medical-issues">
            <label>Previous Medical Issues</label>
            <div className="issue-tags mt-1">
              {patientData.medicalIssue.map((issue, index) => (
                <div
                  key={index}
                  className={`issue-tag ${
                    issue.isActive ? "active" : "inactive"
                  }`}
                >
                  {issue.issue}
                </div>
              ))}
            </div>
          </div>

          {/* Treatment History */}
          <div className="treatment-history">
            <label>Treatment History</label>
            <div className="history-list mt-1">
              {patientData.treatmentHistory.map((history, index) => (
                <div key={index} className="history-item">
                  <span className="history-number">{index + 1}</span>
                  <span className="history-text">{history.treatment}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Allergy */}

          <div className="previous-medical-issues">
            <label>Known Allergies</label>
            <div className="issue-tags mt-1">
              {patientData.allergyList.map((allergy, index) => (
                <div
                  key={index}
                  className={`issue-tag ${
                    allergy.isActive ? "active" : "inactive"
                  }`}
                >
                  {allergy.issue}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Appointment Detail */}
        {/* <div className="row mb-3">
          <h5 className="fs-5">Appointment Detail</h5>
          <div className="col-md-4">
            <label>Appointment Date</label>
            <input
              type="date"
              className="form-control mt-1"
              value={patientData?.appointmentDate}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Speciality</label>
            <select className="form-select mt-1" disabled>
              <option value={patientData?.speciality}>
                {patientData?.speciality}
              </option>
            </select>
          </div>
          <div className="col-md-4">
            <label>Doctor</label>
            <select className="form-select mt-1" disabled>
              <option value={patientData?.doctor}>{patientData?.doctor}</option>
            </select>
          </div>
          <div className="col-md-4">
            <label>Shift</label>
            <select className="form-select mt-1" disabled>
              <option value={patientData?.shift}>{patientData?.shift}</option>
            </select>
          </div>
          <div className="col-md-4">
            <label>Consultation Mode</label>
            <select className="form-select mt-1" disabled>
              <option value={patientData?.consultationMode}>
                {patientData?.consultationMode}
              </option>
            </select>
          </div>
        </div> */}
      </form>
    </div>
  );
};

export default PatientForm;
