import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const AgeForm = ({ value, setValue }) => {
  const [isValid, setIsValid] = useState(true);
  const [tempValue, setTempValue] = useState(value?.ageYear || '');

  const title = 'Only numbers';
  const pattern = '^[0-9]+$';

  // Debugging logs
  useEffect(() => {
    setTempValue(value?.ageYear || '');
  }, [value]);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setTempValue(inputValue);

    const regex = new RegExp(pattern);
    const isValidInput = regex.test(inputValue);
    setIsValid(isValidInput);

    // Only set ageYear if input is valid
    if (isValidInput) {
      // console.log('Valid input:', inputValue);
      setValue?.setAgeYear(inputValue);
    } 
    // else {
    //   console.log('Invalid input:', inputValue);
    // }
  };

  return (
    <Form.Group className={'col-2 mb-3 age-form-container1'}>
      <Form.Label className='label-text-content'>
        Age <span className='text-danger'> *</span>
      </Form.Label>
      <Form.Control
        type={'text'}
        value={value?.dOB ? `${value?.ageYear} years` : tempValue}
        className='input-text-control'
        required={true}
        disabled={!!value?.dOB} // Disable if dOB exists
        placeholder={'YY'}
        onChange={handleChange}
        pattern={pattern}
        title={title}
        maxLength={2}
        isInvalid={!isValid && !value?.dOB}
      />
      {!isValid && (
        <Form.Text className='text-danger'>
          {title || 'Invalid input'}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default AgeForm;
