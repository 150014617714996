import React, { useEffect, useRef, useState } from "react";
import { MEDIA_URL } from "../../../../../axios";
import moment from "moment";
import { TbPencil } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { Switch } from "antd";
import usePhysicianServices from "../../../../../services/api-services/physicianServices";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import sampleImg from "../../../../../../assets/logos/doc-sample.png"
const PhysicianListCard = ({ data, index, functions, getPhysician }) => {
  const navigate = useNavigate();
  const { physicianDelete, physicianAction } = usePhysicianServices();

  const [isActive, setIsActive] = useState("active");
  const detailsRef = useRef(null);
  const handleToggle = async () => {
    const newStatus = isActive ? "deactivate" : "activate";
    setIsActive(!isActive);

    try {
      const response = await physicianAction(data.basic_info.id, newStatus);
      if (response?.success) {
        Swal.fire("Success!", `Physician has been ${newStatus}d.`, "success");
        getPhysician();
      } else {
        Swal.fire(
          "Error!",
          `There was an issue ${newStatus}ing the physician.`,
          "error"
        );
      }
    } catch (error) {
      Swal.fire(
        "Error!",
        "An error occurred while changing the physician's status.",
        "error"
      );
      console.error(error);
    }
  };
  const [showDetails, setShowDetails] = useState(false);

  const handleShowDetails = () => {
    setShowDetails((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (detailsRef.current && !detailsRef.current.contains(event.target)) {
      setShowDetails(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDeleteWithConfirmation = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { value: password } = await Swal.fire({
          title: "Enter your password",
          input: "password",
          inputPlaceholder: "Enter your password ...",
          inputAttributes: {
            autocapitalize: "off",
            autocorrect: "off",
          },
          showCancelButton: true,
        });

        if (password) {
          try {
            const response = await physicianDelete(
              data.basic_info.id,
              password
            );
            if (response?.success) {
              Swal.fire("Deleted!", "Physician has been deleted.", "success");
              getPhysician();
            } else {
              Swal.fire(
                "Invalid Password!",
                "The password you entered is incorrect.",
                "error"
              );
            }
          } catch (e) {
            Swal.fire(
              "Error!",
              "An error occurred while trying to delete the physician.",
              "error"
            );
            console.error(e);
          }
        }
      }
    });
  };

  const handlePhysicianEdit = (data) => {
    if (data?.id) {
      navigate("/physician-register", {
        state: {
          PhysicianEditData: data,
        },
      });
    }
  };

  return (
    <div
      key={index}
      className="col-12 col-md-6 col-lg-4 p-2 physician-new-box"
      style={{ position: "relative" }}
    >
      {showDetails && (
        <div
          className="row"
          id="threedot"
          style={{
            position: "absolute",
            top: "1",
            right: "0",
            zIndex: 10,
            width: "100%",
          }}
        >
          <div className="col-4"></div>
          <div
            className="col-7 col-8 bg-light rounded me-1 p-4"
            ref={detailsRef}
          >
            <div className="container-fluid">
              <div className="d-flex justify-content-start">
                <span className="mx-2">Active/Deactive :</span>
                <Switch
                  checkedChildren="✔"
                  unCheckedChildren="✘"
                  className="fw-bold"
                  checked={isActive}
                  onChange={handleToggle}
                  style={{
                    backgroundColor: isActive ? "green" : "red",
                  }}
                />
              </div>
              <div className="actions d-flex flex-column ">
                <div className="edit-button-1 p-1">
                  <div
                    className="btn btn-primary mb-2"
                    onClick={() => handlePhysicianEdit(data)}
                  >
                    <TbPencil size={17} />
                  </div>{" "}
                  <span className="ps-1">Edit</span>
                </div>
                <div className="remove-button-1 p-1">
                  <div
                    className="btn btn-danger"
                    onClick={() => {
                      handleDeleteWithConfirmation(data);
                    }}
                  >
                    <RiDeleteBinLine size={17} />
                  </div>{" "}
                  <span className="ps-1">Remove</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row m-1 py-2 px-1 ps-3 d-flex justify-content-between">
        <div className="col-4 px-0 pro-image-cont">
          {/* <div className="col-4 col-md-3 col-lg-2 p-0 physician-img-container"> */}
          <img
            src={data?.basic_info?.image ? MEDIA_URL + data?.basic_info?.image : sampleImg}
            alt=""
            className="physician-profile-image "
            onClick={() => functions(data.id)}
            style={{ cursor: "pointer" }}
          />
          {/* </div> */}
        </div>
        <div className="col-6 px-0 pt-2">
          <span className="label">ID : </span>
          <span className="text">{data?.user_id}</span>
          <br />
          <span className="label">Name : </span>
          <span className="text"> {data?.basic_info?.full_name}</span>
        </div>
        <div className="col-2 px-0 d-flex justify-content-end align-items-start">
          <input
            className="bg-light rounded fw-bold ps-2 pe-2 pb-2"
            type="button"
            value={"..."}
            onClick={handleShowDetails}
          />
        </div>
      </div>
      <div className="row m-1 p-3 physician-new-box1">
        <div className="col-5 text-start">Qualification :</div>
        <div className="col-7 text-end">
          {data?.qualifications &&
            data?.qualifications?.map((item, index) => {
              if (index !== data.qualifications.length - 1) {
                return item + ", ";
              } else {
                return item;
              }
            })}
        </div>
        <div className="col-5 text-start">Speciality :</div>
        <div className="col-7 text-end">
          {data?.medical_id?.speciality_type}
        </div>
        <div className="col-5 text-start">Joining Date :</div>
        <div className="col-7 text-end">
          {data?.medical_id?.joined_date &&
            moment(new Date(data.medical_id.joined_date)).format("DD/MM/YYYY")}
        </div>
      </div>
    </div>
  );
};

export default PhysicianListCard;
