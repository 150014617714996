import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAxios from "../../hooks/useAxios";

const useSuperAdminServices = () => {
//   const axiosPrivate = useAxiosPrivate();
  const axios = useAxios();

  //POST REQUESTS

  //post Admin Registration details
  const postAdminRegistration = async (data) => {
    const response = await axios.post("account/admin_register/", data);
    return response.data;
  };

  //get Admin Registration Status
  const getAdminStatus = async () => {
    const response = await axios.get("account/admin_check/");
    return response.data;
  };

  return {
    //POST services
    postAdminRegistration,
    //GET services
    getAdminStatus,
  };
};

export default useSuperAdminServices;
