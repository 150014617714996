import React, { useEffect, useState } from 'react'
import useConsultationServices from '../../../../../services/api-services/consultationServices'
import Swal from 'sweetalert2'
import { Modal } from 'react-bootstrap'
import InputTextBox from '../../../../../shared/form-components/form-text/InputTextBox'
import { BiSolidEdit } from 'react-icons/bi'

const VitalTypeSettings = () => {
    const [showManageModal, setShowManageModal] = useState(false)
    const [vitalTypeList, setVitalTypeList] = useState([])
    const [vitalId, setVitalId] = useState('')
    const [vitalType, setVitalType] = useState('')
    const [vitalUnit, setVitalUnit] = useState('')

    const {
        getVitalTypes,
        postNewVitalType,
        putVitalType
    } = useConsultationServices()

    const getAllVitalTypes = async() => {
        try{
            const response = await getVitalTypes()
            if(response?.success){
                setVitalTypeList(response?.data)
            }
            else{
                console.log(response?.errors)
            }
        }catch(err){
            setVitalTypeList([])
            console.log(err?.response?.errors)
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        if(!vitalType || !vitalUnit){
            Swal.fire('','Fill out all given fields','error')
        }
        try{
            const data = JSON.stringify(
                {
                    vital_name:vitalType,
                    unit:vitalUnit
                }
            )
            var response;
            if(vitalId){
                response = await putVitalType(vitalId, data)
            }
            else{
                response = await postNewVitalType(data)
            }
            if(response?.success){
                Swal.fire('', response?.message, 'success')
                handleCancel()
                getAllVitalTypes()
            }
            else{
                Swal.fire('', response?.message, 'error')
            }
        }catch { }
    }

    const handleEdit = async(item) =>{
        setVitalId(item?.id)
        setVitalType(item?.vital_name)
        setVitalUnit(item?.unit)
        handleManageBtn()
    }

    const handleManageBtn = () => {
        setShowManageModal(true)
    }

    const handleCancel = () => {
        setVitalType('')
        setVitalUnit('')
        setVitalId('')
        setShowManageModal(false)
    }

    useEffect(()=>{
        getAllVitalTypes()
    },[])

    return (
        <div>
            <Modal
                show={showManageModal}
                onHide={()=>setShowManageModal(false)}
                size='sm'
                centered={true}
                contentClassName="custom-modal"
            >
                <Modal.Header
                    closeButton
                    className='custom-modal-header'
                >
                    Manage Vital Types
                </Modal.Header>
                <Modal.Body
                    className='custom-modal-body'
                >
                    <form className='custom-modal-content row mx-0'>
                        <div
                            className='col-12 row mx-0'
                        >
                            <InputTextBox
                                required={true}
                                label={'Vital Type Name'}
                                containerClass={'col-12 mb-3'}
                                onChange={setVitalType}
                                defvalue={vitalType}
                            />
                            <InputTextBox
                                required={true}
                                label={'Vital Type Unit'}
                                containerClass={'col-12 mb-3'}
                                onChange={setVitalUnit}
                                defvalue={vitalUnit}
                            />
                        </div>
                        <div
                            className='col-12 row my-3'
                        >
                            {/* <span className='col-6' /> */}
                            <div
                                className='col-6 ps-2'
                            >
                                <div 
                                    className='crop-cancel-btn'
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </div>
                            </div>
                            <div
                                className='col-6 ps-4'
                            >
                                <div 
                                    className='crop-submit-btn'
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </div>
                            </div>
                        </div>  
                    </form>
                </Modal.Body>
            </Modal>
            <div className="col-2 my-2">
                <div 
                    className="tabpane-extra-btns"
                    onClick={handleManageBtn}
                >
                    Manage Vital Types
                </div>
            </div>
            <div className='patient-list-container'>
                <div className="patient-table-container container-white">
                    <table className='table patient-list-table'>
                        <thead className='patient-list-table-head'>
                            <tr>
                                <th>Vital Type Name</th>
                                <th className='text-center'>Unit</th>
                                <th className='text-center'>Active</th>
                                <th className='text-center'>Edit</th>
                            </tr>
                        </thead>
                        <tbody className='patient-list-table-body'>
                        {vitalTypeList && vitalTypeList.map((item, index) => {
                            return (
                            <tr key={index}>
                                <td>{item?.vital_name}</td>
                                <td className='text-center'>{item?.unit}</td>
                                <td className='text-center'>{item?.active?'True':'False'}</td>
                                <th className='text-center text-primary'>
                                    <BiSolidEdit onClick={()=>handleEdit(item)} size={'1.4rem'}/>
                                </th>
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    )
}

export default VitalTypeSettings
